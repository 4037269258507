import { Component, Vue } from "vue-property-decorator";
import Template from "./preOrderSaveModal.template.vue";

@Component({
  mixins: [Template]
})
export class PreOrderSaveModalComponent extends Vue {
  public revert() {
    this.$emit("resolve", "original");
  }

  public update() {
    this.$emit("resolve", "update");
  }

  public cancel() {
    this.$emit("reject");
  }
}
