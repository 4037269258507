import {
  AvailableDiscountGroup,
  DiscountToApply,
  OrderItem
} from "@/interfaces/order";
import { PosSelectProductDiscount } from "@/metadata/product";
import {
  TableComponent,
  TableHeader,
  TableSelectEvent
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./ProductsSelect.template.vue";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  }
})
export default class ProductsSelect extends Vue {
  @Prop({ required: true }) public group!: AvailableDiscountGroup;
  @Prop({ required: true }) public orderItems!: OrderItem[];
  public headers: TableHeader[] = PosSelectProductDiscount;
  public orderItemsSelected: OrderItem[] = [];
  public orderItemsCopy: OrderItem[] = [];
  protected validQuantities: boolean = true;

  public setOrderItems(event: TableSelectEvent) {
    this.orderItemsSelected = cloneDeep(event.currentSelection);
  }

  get items() {
    this.orderItemsSelected = cloneDeep(this.orderItems);
    return this.orderItems.filter(orderItem =>
      this.group.items.includes(orderItem.id!)
    );
  }

  public apply() {
    const discounts: DiscountToApply[] = [];
    this.orderItemsSelected.forEach(orderItem =>
      discounts.push({
        discount_id: this.group.discount.id,
        item_id: orderItem.id!,
        amount: +orderItem.quantity!,
        auto_apply: !!this.group.discount.auto_apply
      })
    );
    if (this.validQuantities) {
      this.$emit("resolve", discounts);
    }
  }

  public cancel() {
    this.$emit("reject");
  }

  public quantity(orderItemId: number): number {
    return this.orderItemsCopy.find(h => h.id === orderItemId)!.quantity!;
  }

  protected validateQuantity(data: any, value: number) {
    const isValid: boolean =
      Number(value) > 0 && Number(value) <= this.quantity(data.item.id);
    this.validQuantities = isValid;
    return isValid || this.$t("cart_discounts.quantity_error");
  }

  protected created() {
    this.orderItemsCopy = cloneDeep(this.orderItems);
    this.headers
      .find(h => h.value === "quantity")!
      .validateFns!.push(this.validateQuantity);
  }
}
