import PosProductListComponent from "@/components/retail/pos/pointOfSale/posProductList/PosProductList.component";
import PosSelectorsComponent from "@/components/retail/pos/pointOfSale/posSelectors/PosSelectors.component";
import PosCardsPlaceholderComponent from "@/components/sharedComponents/placeholders/posCards/posCardsPlaceholder.component";
import { Batch } from "@/interfaces/batch";
import { Location } from "@/interfaces/location";
import { AddToCartPayload } from "@/interfaces/order";
import {
  defaultPrescriptionDetails,
  PrescriptionDetails
} from "@/interfaces/prescriptionDetails";
import { Product } from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import flattenDeep from "lodash/flattenDeep";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import sumBy from "lodash/sumBy";
import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import PosImageComponent from "../posImage/PosImage.component";
import Template from "./PosProductCard.template.vue";

@Component({
  mixins: [Template],
  components: {
    PosSelectorsComponent,
    PosImageComponent,
    PosCardsPlaceholderComponent
  }
})
export default class PosProductCardComponent extends PosProductListComponent {
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  @Getter("loading", { namespace: "ProductModule" })
  public loading: boolean = false;
  public loadingItem: string | null = null;

  @Prop({ default: () => null })
  public pharmacistSelected!: number | null;

  public prescriptionDetail: PrescriptionDetails = defaultPrescriptionDetails;
  public quantatyRooms(product: Product) {
    return sumBy(
      flattenDeep(map(product.batches as Batch[], "rooms")),
      "quantity"
    );
  }
  get imagePriority(): Array<{
    priority: number;
    type: string;
  }> {
    return orderBy(
      [
        {
          priority: this.currentRetailSettings!.product_images_priority!,
          type: "product"
        },
        {
          priority: this.currentRetailSettings.brand_images_priority!,
          type: "brand"
        },
        {
          priority: this.currentRetailSettings.strain_images_priority!,
          type: "strain"
        }
      ],
      ["priority"],
      ["asc"]
    );
  }

  get buttonLabel() {
    return "add_to_cart";
  }

  public fixName(name: string) {
    return name.substr(0, 40).concat(name.length > 40 ? " ..." : "");
  }

  public expandAction(j: number) {
    if (this.openItems.includes(`tdt__data${String(j)}`)) {
      setTimeout(
        () =>
          this.openItems.splice(
            this.openItems.indexOf(`tdt__data${String(j)}`),
            1
          ),
        100
      );
    } else {
      this.openItems.push(`tdt__data${String(j)}`);
    }
  }

  public addItemToCart(item: AddToCartPayload) {
    this.loadingItem = item.orderItem[0].product_sku!;
    this.$emit("addItem", item);
  }

  protected mounted() {
    this.$watch("loading", () => (this.loadingItem = null));
  }
}
