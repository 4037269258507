import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./PosModalActiveCar.template.vue";

@Component({
  mixins: [Template],
  components: {}
})
export default class PosModalActiveCarComponent extends Vue {
  @Prop() public message!: string;

  public close() {
    this.$emit("resolve", true);
  }
}
