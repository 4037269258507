import { PreOrder } from "@/interfaces/orderFulfillment";
import { sumObjArrayBy } from "@/utils/math.utils";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./PosModalPreOrderChanges.template.vue";

// Modal modified items Row
interface ModalModifiedRow {
  product_name: string;
  ordered_quantity: number;
  filled_quantity: number;
  difference: number;
  unit: string;
}

// Modal canceled items Row
interface ModalCanceledRow {
  product_name: string;
  quantity: number;
  unit: string;
  reason: string;
}

/**
 * Component
 */
@Component({
  mixins: [Template]
})
export default class PosModalPreOrderChangesComponent extends Vue {
  @Prop({ required: true })
  public preOrder!: PreOrder;
  public modifiedRows: ModalModifiedRow[] = [];
  public canceledRows: ModalCanceledRow[] = [];

  public close() {
    this.$emit("reject");
  }

  protected mounted() {
    this.preOrder.items.forEach(element => {
      if (element.updated) {
        const orderedTotal = sumObjArrayBy(element.ordered, it => +it.quantity);
        const filledTotal = sumObjArrayBy(element.filled, it => +it.quantity);
        this.modifiedRows.push({
          product_name: element.product.name,
          ordered_quantity: orderedTotal,
          filled_quantity: filledTotal,
          difference: orderedTotal - filledTotal,
          unit: element.ordered[0].in_store_quantity_unit
        });
      } else if (element.canceled) {
        const orderedTotal = sumObjArrayBy(element.ordered, it => +it.quantity);
        this.canceledRows.push({
          product_name: element.product.name,
          reason: element.cancel_reason,
          quantity: orderedTotal,
          unit: element.ordered[0].in_store_quantity_unit
        });
      }
    });
  }
}
