import { loyaltyDiscountsCodes } from "@/enums/discountManager";
import { Discount } from "@/interfaces/discount";
import { ModalConfirm } from "@/interfaces/notification";
import {
  ApplyLoyaltyFields,
  AvailableDiscount,
  Order,
  OrderItem
} from "@/interfaces/order";
import {
  MultipleLoyaltyDiscount,
  WholeLoyaltyDiscount
} from "@/metadata/product";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import { messagesService } from "@/services/messages.service";
import { CallbackPromise } from "@/types/types";
import {
  LoyaltyConfig,
  LoyaltyRedemption
} from "@/vuex/modules/order/order.types";
import {
  MultiSelectConfig,
  TableComponent,
  TableHeader,
  TableItem
} from "helix-vue-components";
import debounce from "lodash/debounce";
import { round } from "mathjs";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./ApplyLoyaltyModal.template.vue";
const namespace: string = "OrderModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  }
})
export default class ApplyLoyaltyModal extends Vue {
  @Prop() public loyaltyRemaining!: AvailableDiscount;
  @Prop() public order!: Order;
  @Prop() public orderItem!: OrderItem[];
  public currentStep = 1;
  @Prop()
  public modalData!: ModalConfirm;
  @Getter("loyaltyConfig", { namespace })
  public loyaltyConfig!: LoyaltyConfig;
  @Getter("discounts", { namespace })
  public discounts!: Discount[];
  @Action("addDiscount", { namespace })
  public addDiscountAction!: CallbackPromise<void>;
  public points = 1;
  public remaingPoints = 0;
  public units = 0;
  public weight = 0;
  public batches = 0;
  public amount = 1;
  public amountSum = 0;
  public totalWholeOrderDiscount = 0;
  public unitPayment = "$";
  public amountStrings: {} = {};
  public pointBreaks: number[] = [];
  public discountTypes: string = "multiple_item";
  public isEdit = false;
  public postTax = 0;
  public isLoading = false;
  public newPoints = 0;

  public headers: TableHeader[] = WholeLoyaltyDiscount;
  public headerMultiple: TableHeader[] = MultipleLoyaltyDiscount;
  public applyLoyaltyFields: ApplyLoyaltyFields[] = [];

  public defaultSelectedItems: number[] = [];
  public pinService!: SecurityPinService;
  public pointDiscount: Discount | null = null;

  public multiSelectionConfig: MultiSelectConfig = {
    disableCondition: item => Boolean(item.points_limit),
    tooltip: "loyalty_cart.no_enough_loyalty_point_tooltip"
  };

  public debouncePointsChange = debounce(async (scope: any) => {
    if (scope.points < scope.redemptionStep) {
      scope.points = scope.redemptionStep;
    }
    scope.amount = scope.points / scope.redemptionStep;
  }, 500);
  public debounceAmountChange = debounce(async (scope: any) => {
    if (scope.amount < 1) {
      scope.amount = 1;
    }
    scope.points = scope.amount * scope.redemptionStep;
  }, 500);

  public async addDiscount() {
    const discountFields = [];
    if (this.applyLoyaltyFields) {
      for (const items of this.applyLoyaltyFields) {
        if (items.is_selected) {
          if (this.discountTypes === "multiple_item") {
            this.amountSum = this.amountSum + items!.price_final!;
            discountFields.push({
              points: Number(items.loyalty_points),
              discount_id: this.pointDiscount!.id,
              item_id: Number(items.id)
            });
          } else {
            this.amountSum = this.amountSum + items!.price_final!;
            discountFields.push({
              points: this.points,
              discount_id: this.pointDiscount!.id,
              items_ids: this.applyLoyaltyFields
                .filter(mdf => mdf.is_selected)
                .map(mdf => mdf.id)
            });
          }
        }
      }
    }

    if (
      this.orderItem &&
      this.pointSum > this.amountSum &&
      this.discountTypes === "multiple_item"
    ) {
      this.amountSum = 0;
      return messagesService.renderWarningMessage(
        "loyalty_points_exceed_order_item_total"
      );
    } else if (
      this.orderItem &&
      this.amount > this.amountSum &&
      this.discountTypes === "whole_order"
    ) {
      this.amountSum = 0;
      return messagesService.renderWarningMessage(
        "loyalty_points_exceed_order_item_total"
      );
    }

    this.addDiscountAction({
      discount: discountFields,
      isEdit: this.isEdit
    }).then(() => this.$emit("resolve", null));
  }
  public decreasePoints() {
    this.points -= 1;
    this.amount -= +(1 / this.redemptionStep).toFixed(5);
    this.checkLimits();
  }
  public increasePoints(points: number) {
    if (points) {
      this.points = +this.points + points;
      this.amount = +this.amount + points / this.redemptionStep;
    } else {
      this.points = +this.points + 1;
      this.amount = this.points / this.redemptionStep;
    }
    this.checkLimits();
  }
  public decreaseAmount() {
    this.amount -= 1;
    this.points -= this.redemptionStep;
    this.checkLimits();
  }
  public increaseAmount() {
    this.amount = +this.amount + 1;
    this.points = +this.points + this.redemptionStep;
    this.checkLimits();
  }

  public checkLimits() {
    if (
      this.points > this.loyaltyRedemption.loyalty_points ||
      this.amount > this.loyaltyRedemption.redeem_value
    ) {
      messagesService.renderWarningMessage(
        "loyalty_points.override_points_available"
      );
      this.points = +this.loyaltyRedemption.loyalty_points;
      this.amount = +this.loyaltyRedemption.redeem_value;
    } else if (this.points < this.minPoints || this.amount < 1) {
      this.points = +this.minPoints;
      this.amount = +this.minPoints / +this.redemptionStep;
    }
  }
  public useAll() {
    this.points = this.loyaltyRedemption.loyalty_points;
    this.amount = this.loyaltyRedemption.redeem_value;
  }

  public notReady() {
    messagesService.renderWarningMessage("not_ready_yet");
  }

  public changePoints() {
    if (this.points && !/^[0-9]{0,7}?$/.test(String(this.points))) {
      this.points = parseInt(String(this.points), 10);
    }
    this.debouncePointsChange(this);
    this.checkLimits();
  }
  public changeAmount() {
    if (this.amount && !/^[0-9]{0,7}?$/.test(String(this.amount))) {
      this.amount = parseInt(String(this.amount), 10);
    }
    this.debounceAmountChange(this);
    this.checkLimits();
  }
  public updateType(discountType: string) {
    this.discountTypes = discountType;
  }
  public nextPage() {
    this.calculateRemaingPoints();
    this.currentStep = this.currentStep + 1;
    if (this.currentStep === 2) {
      this.isLoading = true;
      const loyaltyType =
        this.discountTypes === "multiple_item"
          ? loyaltyDiscountsCodes.LOYALTY_PER_ITEM
          : loyaltyDiscountsCodes.LOYALTY_PER_ORDER;
      const index = this.discounts.findIndex(
        item => String(item.codename) === String(loyaltyType)
      );

      if (index !== -1) {
        this.pointDiscount = this.discounts[index];
      }
      this.setTableFields();
    }
  }
  public backPage() {
    this.currentStep = this.currentStep - 1;
    this.applyLoyaltyFields = [];
    this.defaultSelectedItems = [];
  }
  public setPointBreaks() {
    if (this.loyaltyConfig) {
      this.points = +this.minPoints;
      this.debouncePointsChange(this);

      if (this.pointBreaks.length) {
        this.pointBreaks = [];
      }
      if (10 < this.totalAmount) {
        this.pointBreaks.push(10);
      }
      if (100 < this.totalAmount) {
        this.pointBreaks.push(100);
      }
      if (1000 < this.totalAmount) {
        this.pointBreaks.push(1000);
      }
    }
  }
  public get loyaltyRedemption(): LoyaltyRedemption {
    return this.loyaltyConfig.loyalty_redemption || 0;
  }
  public get minPoints() {
    return this.loyaltyConfig.loyalty_point.redemption_value_per_points || 0;
  }
  public get redemptionStep() {
    return (
      +this.minPoints /
        +this.loyaltyConfig.loyalty_point.redemption_value_dollars! || 1
    );
  }
  public get totalAmount() {
    return this.loyaltyRedemption.loyalty_points || 0;
  }

  public get pointSum() {
    let total = 0;
    if (this.applyLoyaltyFields) {
      this.applyLoyaltyFields.forEach(item => {
        if (item.is_selected) {
          total = total + Number(item.loyalty_points);
        }
      });
    }
    return total;
  }

  public get totalDiscount() {
    let total = 0;
    this.applyLoyaltyFields.forEach(item => {
      if (item.is_selected) {
        total += Number(item.loyalty_amount);
      }
    });
    return total.toFixed(2);
  }
  public getTotalWholeOrderDiscount() {
    this.totalWholeOrderDiscount = 0;
    const filterItems = this.applyLoyaltyFields.filter(
      item => item.is_selected
    );
    if (filterItems.length) {
      this.totalWholeOrderDiscount = round(this.amount, 2);
    }
  }
  public mounted() {
    this.loyaltyRedemption.loyalty_points = this.loyaltyRemaining.points!;
    this.pinService = new SecurityPinService();
    this.postTax = this.loyaltyConfig.loyalty_point.post_tax_loyalty_points;

    this.setPointBreaks();
    this.totalBatches();
    this.totalWeight();
    this.totalUnits();
    this.getTotalWholeOrderDiscount();
  }

  public calculateRemaingPoints() {
    this.newPoints = 0;
    if (this.discountTypes === "multiple_item") {
      this.newPoints = this.orderItem.length * this.points;
      this.remaingPoints =
        this.loyaltyRedemption.loyalty_points - this.newPoints;
    } else {
      this.remaingPoints = this.loyaltyRedemption.loyalty_points - this.points;
    }
  }

  public checkExclusiveDiscount(data: ApplyLoyaltyFields) {
    return data.discounts!.filter(disc => disc.exclusive_discount === 1).length
      ? true
      : false;
  }

  public onSelectItem(data: TableItem) {
    if (data.eventItem) {
      const index = this.applyLoyaltyFieldsIndex(data.eventItem.item);
      if (index !== -1) {
        this.applyLoyaltyFields[index].is_selected = data.eventItem.status;
        if (!this.applyLoyaltyFields[index].is_selected) {
          this.remaingPoints += Number(
            this.applyLoyaltyFields[index].loyalty_points
          );
          this.applyLoyaltyFields[index].loyalty_amount = 0;
          this.applyLoyaltyFields[index].loyalty_points = 0;
          this.applyLoyaltyFields[index].points_limit = false;
          this.displayWarning(this.remaingPoints);
        }
      }
      this.checkPointsLimit(data.eventItem!.item);
    } else {
      data.currentSelection.map((items: ApplyLoyaltyFields) => {
        const index = this.applyLoyaltyFieldsIndex(items);
        if (index !== -1) {
          this.applyLoyaltyFields[index].is_selected = data.checkAll;
        }
        this.checkPointsLimit(this.applyLoyaltyFields[index]);
      });
    }
    this.totalBatches();
    this.totalWeight();
    this.totalUnits();
    this.getTotalWholeOrderDiscount();
  }
  public totalWeight() {
    this.weight = 0;
    if (this.applyLoyaltyFields) {
      this.applyLoyaltyFields.forEach(data => {
        if (data.product_unit === "g") {
          if (data.is_selected) {
            this.weight += Number(data.quantity)!;
          }
        }
      });
    }
  }

  public totalUnits() {
    this.units = 0;
    if (this.applyLoyaltyFields) {
      this.applyLoyaltyFields.forEach(data => {
        if (data.product_unit === "u") {
          if (data.is_selected) {
            this.units += Number(data.quantity)!;
          }
        }
      });
    }
  }

  public totalBatches() {
    this.batches = 0;
    if (this.applyLoyaltyFields) {
      this.applyLoyaltyFields.forEach(data => {
        if (data.is_selected) {
          this.batches++;
        }
      });
    }
  }

  public setTableFields() {
    this.orderItem.forEach(items => {
      const itemPrice = Number(items.base_price) + Number(items.tax_amount);
      this.applyLoyaltyFields.push({
        batch: items.batch_barcode_uid,
        discounts: items.discounts,
        product: items.product_info!.name,
        loyalty_amount: round(this.amount, 2),
        loyalty_points: this.points,
        price_final: items.price_final,
        quantity: items.quantity,
        product_unit: items.product_info!.product_unit,
        product_info: items.product_info,
        id: items.id,
        is_selected: true
      });
      this.defaultSelectedItems.push(Number(items.id));
    });
    this.totalBatches();
    this.totalWeight();
    this.totalUnits();
    this.checkLoyaltyPointsLimit();
    this.getTotalWholeOrderDiscount();
  }

  public applyLoyaltyFieldsIndex(data: ApplyLoyaltyFields) {
    const index = this.applyLoyaltyFields.findIndex(
      item => item.id === data.id
    );
    return index;
  }

  public checkLoyaltyPointsLimit() {
    const filteredFields = this.applyLoyaltyFields.filter(
      item => item.is_selected
    );
    const countedField: number[] = [];
    let loyaltyPointsValue = 0;
    filteredFields.map((items: ApplyLoyaltyFields) => {
      loyaltyPointsValue += Number(items.loyalty_points);
      if (loyaltyPointsValue <= this.loyaltyRedemption.loyalty_points) {
        countedField.push(Number(items.id));
      }
    });

    if (this.discountTypes === "multiple_item") {
      this.applyLoyaltyFields.map(items => {
        const fieldIndex = countedField.findIndex(
          item => Number(item) === Number(items.id)
        );
        if (
          fieldIndex === -1 &&
          loyaltyPointsValue >= this.loyaltyRedemption.loyalty_points
        ) {
          const defaultItemIndex = this.defaultSelectedItems.findIndex(
            item => item === items.id
          );
          if (defaultItemIndex !== -1) {
            this.defaultSelectedItems.splice(defaultItemIndex, 1);
          }
          items.is_selected = false;
          items.loyalty_amount = 0;
          items.loyalty_points = 0;
          items.points_limit = true;
        }
      });
    } else {
      this.applyLoyaltyFields.map(items => {
        const fieldIndex = countedField.findIndex(
          item => Number(item) === Number(items.id)
        );
        items.is_selected = true;
        items.points_limit = true;
      });
    }
    this.isLoading = false;
  }
  public displayWarning(point: number) {
    return point < 0 ? true : false;
  }
  public displayApplyButton() {
    if (this.discountTypes === "multiple_item") {
      return (
        !this.defaultSelectedItems.length ||
        this.pointSum > this.loyaltyRedemption.loyalty_points
      );
    } else {
      return !this.defaultSelectedItems.length;
    }
  }
  public checkPointsLimit(data: ApplyLoyaltyFields) {
    this.isLoading = true;
    this.defaultSelectedItems = [];
    const filteredFields = this.applyLoyaltyFields.filter(
      item =>
        item.id !== data.id &&
        item.is_selected &&
        (item.loyalty_points || Number(item.loyalty_points) !== 0)
    );
    let countedField: number[] = [];
    let loyaltyPointsValue = 0;
    if (data.is_selected) {
      countedField = [Number(data.id)];
      loyaltyPointsValue = data.loyalty_points;
    }
    filteredFields.map(items => {
      loyaltyPointsValue += Number(items.loyalty_points);
      countedField.push(Number(items.id));
    });
    this.applyLoyaltyFields.map(items => {
      const fieldIndex = countedField.findIndex(
        item => Number(item) === Number(items.id)
      );
      if (
        fieldIndex === -1 &&
        loyaltyPointsValue > this.loyaltyRedemption.loyalty_points
      ) {
        items.is_selected = false;
        items.loyalty_amount = 0;
        items.loyalty_points = 0;
      } else {
        items.points_limit = false;
      }
    });
    this.setDefaultFields();
  }
  public setDefaultFields() {
    this.applyLoyaltyFields.map(items => {
      if (items.is_selected) {
        this.defaultSelectedItems.push(Number(items.id));
      }
    });
    this.isLoading = false;
  }

  public changeTablePoints(data: ApplyLoyaltyFields, operation: string) {
    const index = this.applyLoyaltyFields.findIndex(
      item => item.product === data.product
    );
    let loyaltyPoints;
    if (index !== -1) {
      if (operation === "increase") {
        loyaltyPoints = Number(data.loyalty_points) + 1;
        this.applyLoyaltyFields[index].loyalty_points = loyaltyPoints;
        this.applyLoyaltyFields[index].loyalty_amount =
          loyaltyPoints /
          this.loyaltyConfig.loyalty_point.redemption_value_per_points!;
      } else if (operation === "decrease") {
        if (data.loyalty_points > 0) {
          loyaltyPoints = Number(data.loyalty_points) - 1;
          this.applyLoyaltyFields[index].loyalty_points = loyaltyPoints;
          this.applyLoyaltyFields[index].loyalty_amount =
            loyaltyPoints /
            this.loyaltyConfig.loyalty_point.redemption_value_per_points!;
        } else if (data.loyalty_points <= 0) {
          loyaltyPoints = Number(data.loyalty_points);
          this.applyLoyaltyFields[index].loyalty_points = 0;
          this.applyLoyaltyFields[index].loyalty_amount = 0;
        }
      } else {
        if (data.loyalty_points >= 0) {
          loyaltyPoints = data.loyalty_points;
          this.applyLoyaltyFields[index].loyalty_points = loyaltyPoints;
          this.applyLoyaltyFields[index].loyalty_amount =
            loyaltyPoints /
            this.loyaltyConfig.loyalty_point.redemption_value_per_points!;
        } else if (data.loyalty_points < 0) {
          this.applyLoyaltyFields[index].loyalty_points = 0;
          this.applyLoyaltyFields[index].loyalty_amount = 0;
        }
      }
    }
    this.checkPointsLimit(data);
    this.totalBatches();
    this.totalWeight();
    this.totalUnits();
    this.getTotalWholeOrderDiscount();
  }
  public changeTableAmount(data: ApplyLoyaltyFields, operation: string) {
    const index = this.applyLoyaltyFields.findIndex(
      item => item.product === data.product
    );
    let loyaltyAmount;
    if (index !== -1) {
      if (operation === "increase") {
        loyaltyAmount = Number(data.loyalty_amount) + 1;
        this.applyLoyaltyFields[index].loyalty_amount = loyaltyAmount;
        this.applyLoyaltyFields[index].loyalty_points =
          loyaltyAmount *
          this.loyaltyConfig.loyalty_point.redemption_value_per_points!;
      } else if (operation === "decrease") {
        if (data.loyalty_amount > 0) {
          loyaltyAmount = Number(data.loyalty_amount) - 1;
          this.applyLoyaltyFields[index].loyalty_amount = loyaltyAmount;
          this.applyLoyaltyFields[index].loyalty_points =
            loyaltyAmount *
            this.loyaltyConfig.loyalty_point.redemption_value_per_points!;
        } else if (data.loyalty_amount <= 0) {
          loyaltyAmount = Number(data.loyalty_amount);
          this.applyLoyaltyFields[index].loyalty_points = 0;
          this.applyLoyaltyFields[index].loyalty_amount = 0;
        }
      } else {
        if (data.loyalty_amount >= 0) {
          loyaltyAmount = data.loyalty_amount;
          this.applyLoyaltyFields[index].loyalty_amount = loyaltyAmount;
          this.applyLoyaltyFields[index].loyalty_points =
            loyaltyAmount *
            this.loyaltyConfig.loyalty_point.redemption_value_per_points!;
        } else if (data.loyalty_amount < 0) {
          this.applyLoyaltyFields[index].loyalty_points = 0;
          this.applyLoyaltyFields[index].loyalty_amount = 0;
        }
      }
    }
    this.checkPointsLimit(data);
    this.totalBatches();
    this.totalWeight();
    this.totalUnits();
    this.getTotalWholeOrderDiscount();
  }
}
