import PosProductModalComponent from "@/components/retail/pos/pointOfSale/posProductList/posProductModal/PosProductModal.component";
import PosSelectorsComponent from "@/components/retail/pos/pointOfSale/posSelectors/PosSelectors.component";
import { Batch } from "@/interfaces/batch";
import { Customer } from "@/interfaces/customer";
import { MemberLevel } from "@/interfaces/memberLevel";
import { AddToCartPayload } from "@/interfaces/order";
import {
  defaultPrescriptionDetails,
  PrescriptionDetails
} from "@/interfaces/prescriptionDetails";
import { Product } from "@/interfaces/product";
import { productService } from "@/services/product.service";
import {
  DataTablePlaceholderComponent,
  TablePagination,
  TablePaginationComponent
} from "helix-vue-components";
import orderBy from "lodash/orderBy";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./PosProductList.template.vue";

const stringResults = (gramVal: number, perVal: number) => {
  const results = [];
  if (gramVal) {
    results.push(`${gramVal} mg/g`);
  }
  if (perVal) {
    results.push(`${perVal}%`);
  }
  if (results.length) {
    return results.length === 2
      ? `(${results.join(" & ")})`
      : results.join(" & ");
  }
  return 0;
};

const namespace: string = "ProductModule";

@Component({
  mixins: [Template],
  components: {
    PosSelectorsComponent,
    PosProductModalComponent,
    TablePaginationComponent,
    DataTablePlaceholderComponent
  }
})
export default class PosProductListComponent extends Vue {
  @Prop({ default: () => [] }) public orderItemsIds!: number[];
  @Prop({ default: false }) public loadingProducts!: boolean;
  @Prop({ default: () => [] }) public products!: Product[];
  @Prop({ default: () => null })
  public pharmacistSelected!: number | null;
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Getter("currentCustomer", { namespace: "CustomerModule" })
  public currentCustomer!: Customer;
  @Getter("memberLevels", { namespace: "MemberLevelModule" })
  public memberLevels!: MemberLevel[];
  public openItems: string[] = [];
  public loadingItem: string | null = null;
  public prescriptionDetail: PrescriptionDetails = defaultPrescriptionDetails;
  protected batch: Batch | null = null;

  public openModal(i: number) {
    this.$modals
      .load(
        PosProductModalComponent,
        {
          size: "normal",
          positionY: "top"
        },
        {
          position: i,
          product: this.products[i],
          pharmacistSelected: this.pharmacistSelected
        }
      )
      .then(data => {
        this.$emit("addItem", data);
      })
      .catch(() => {
        // No action on close.
      });
  }

  public isMemberLevelOnly(product: Product) {
    return (
      this.currentCustomer &&
      (product.price_settings.members_only_product &&
        !this.currentCustomer.member_level_id)
    );
  }

  private get getCustomerCurrentMemberLevel() {
    if (
      this.currentCustomer &&
      this.currentCustomer.member_level &&
      this.memberLevels
    ) {
      const level = this.memberLevels.find(
        ml => ml.id === this.currentCustomer.member_level!.id
      );
      return level && level.code;
    }
  }

  public pricePoint(product: Product) {
    const pricingSetting = productService.getLocationPricingRule(
      product,
      this.getCustomerCurrentMemberLevel || null
    );
    if (
      product.price_settings.price_point_by_usable_weight ||
      (product.usable_weight_unit &&
        (product.pricing[0].pricing_style === "weight" &&
          !product.requires_weighing))
    ) {
      let weightValue = product.usable_weight_value || 0;
      const weightUnit = product.usable_weight_unit;
      if (weightUnit === "mg") {
        weightValue = weightValue / 1000;
      } else if (weightUnit === "oz") {
        weightValue = weightValue * 28.35;
      }
      return productService.getPriceBreakForUsableWeight(
        Number(weightValue),
        pricingSetting && pricingSetting.price_breaks
          ? pricingSetting.price_breaks
          : []
      );
    }
    return (pricingSetting && pricingSetting.unit_price) || 0;
  }
  public fixShowResult(result: string) {
    return {
      fixed: result.length > 24,
      resultFixed: result
        .substr(0, 24)
        .concat(result.length > 24 ? " ..." : ""),
      result
    };
  }

  public changePagination(pagination: TablePagination) {
    const paginate = productService.paginationAction();
    paginate(
      {
        currentPage: pagination.currentPage,
        itemsPerPage: pagination.itemsPerPage
      },
      "on_sale"
    );
  }

  public cbdTotalLabs(product: Product) {
    if (!product.marijuana) {
      return { resultFixed: "--" };
    }
    const totalCbd = orderBy(
      (product.batches as Batch[]).filter(batch => batch.sample),
      ["sample.cbd"],
      ["asc"]
    );
    if (totalCbd.length) {
      const min = totalCbd[0].sample;
      const max = totalCbd[totalCbd.length - 1].sample;
      return this.fixShowResult(`${
        min!.cbd !== max!.cbd || min!.cbd_relative! !== max!.cbd_relative!
          ? `Min ${stringResults(min!.cbd, min!.cbd_relative!)} - Max `
          : ""
      }
        ${stringResults(max!.cbd, max!.cbd_relative!)}`);
    }
    return this.fixShowResult(this.$t("no_lab_results").toString());
  }

  public thcTotalLabs(product: Product) {
    if (!product.marijuana) {
      return { resultFixed: "--" };
    }
    const totalThc = orderBy(
      (product.batches as Batch[]).filter(batch => batch.sample),
      ["sample.thc"],
      ["asc"]
    );
    if (totalThc.length) {
      const min = totalThc[0].sample;
      const max = totalThc[totalThc.length - 1].sample;
      return this.fixShowResult(`${
        min!.thc !== max!.thc || min!.thc_relative! !== max!.thc_relative!
          ? `Min ${stringResults(min!.thc, min!.thc_relative!)} - Max `
          : ""
      }
        ${stringResults(max!.thc, max!.thc_relative!)}`);
    } else {
      return this.fixShowResult(this.$t("no_lab_results").toString());
    }
  }

  public expandAction(j: number) {
    const item: HTMLElement = document.getElementById(`tdt__data${String(j)}`)!;
    if (this.openItems.includes(`tdt__data${String(j)}`)) {
      item.style.removeProperty("height");
      setTimeout(
        () =>
          this.openItems.splice(
            this.openItems.indexOf(`tdt__data${String(j)}`),
            1
          ),
        100
      );
    } else {
      item.style.height = "140px";
      this.openItems.push(`tdt__data${String(j)}`);
    }
  }

  public addItemToCart(item: AddToCartPayload) {
    this.loadingItem = item.orderItem[0].product_sku!;
    this.$emit("addItem", item);
  }

  @Watch("products")
  public resetCards() {
    this.openItems = [];
  }

  protected mounted() {
    this.$watch("loading", () => (this.loadingItem = null));
  }
}
