import PrintPreOrderPendingReceiptComponent from "@/components/retail/orderFulfillment/tableOrderFulfillment/orderFulfillmentPrint/PrintPreOrderPendingReceipt.component";
import DiscountComponent from "@/components/retail/pos/pointOfSale/posCart/Discount/Discount.component";
import LoyaltyComponent from "@/components/retail/pos/pointOfSale/posCart/Loyalty/Loyalty.component";
import PosPrescriptionModalComponent from "@/components/retail/pos/pointOfSale/posCart/posPrescriptionModal/PosPrescriptionModal";
import PosProductModalComponent from "@/components/retail/pos/pointOfSale/posProductList/posProductModal/PosProductModal.component";
import { policyList } from "@/enums/permissions";
import { EventBus } from "@/event-bus";
import { currencyFilter } from "@/filters/currency.filter";
import { Customer } from "@/interfaces/customer";
import { Discount } from "@/interfaces/discount";
import { RuleConfig } from "@/interfaces/limits";
import { LocationSalesLimits } from "@/interfaces/location";
import {
  AddToCartPayload,
  LoyalityItem,
  Order,
  OrderItem
} from "@/interfaces/order";
import { defaultPrescriptionDetails } from "@/interfaces/prescriptionDetails";
import { Product } from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import { customerService } from "@/services/customer.service";
import { messagesService } from "@/services/messages.service";
import { orderService } from "@/services/order.service";
import { productService } from "@/services/product.service";
import { Callback } from "@/types/types";
import { getParentSKU } from "@/utils/batch-actions.utils";
import {
  FNS_DATE_FORMATS,
  fnsFormatDate,
  UTCTimeToLocalDate
} from "@/utils/date-fns.utils";
import { truncate } from "@/utils/math.utils";
import { BooleanCheck, CallbackPromise } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import sumBy from "lodash/sumBy";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CompleteCallInComponent from "./completeCallIn/CompleteCallIn.component";
import ExpandedPricesComponent from "./ExpandedPrices/ExpandedPrices.component";
import ExpandedPriceTooltip from "./ExpandedPriceTooltip/ExpandedPriceTooltip.component";
import Template from "./PosCart.template.vue";
import { PreOrderSaveModalComponent } from "./preOrderSaveModal/preOrderSaveModal.component";

const namespace: string = "OrderModule";
interface CartSideBar {
  status: boolean;
  type: string;
  component: string;
  orderItem: OrderItem | null;
}

@Component({
  mixins: [Template],
  components: {
    DiscountComponent,
    LoyaltyComponent,
    PosProductModalComponent,
    PosPrescriptionModalComponent,
    CompleteCallInComponent,
    ExpandedPriceTooltip
  },
  filters: {
    currencyFilter
  }
})
export default class PosCartComponent extends Vue {
  @Action("newOrder", { namespace })
  public createNewOrder!: Callback;
  @Action("deleteOrderItem", { namespace })
  public deleteOrderItemAction!: Callback;
  @Action("deleteOrder", { namespace })
  public deleteOrder!: CallbackPromise<void>;
  @Action("removeDiscount", { namespace })
  public removeDiscountAction!: Callback;
  @Action("removeAutoApplyDiscount", { namespace })
  public removeAutoApplyDiscount!: Callback;
  @Action("removeWholeOrderDiscount", { namespace })
  public removeWholeOrderDiscount!: Callback;
  @Action("updateCustomer", { namespace })
  public updateCustomerAction!: Callback;
  @Action("setCustomer", { namespace: "CustomerModule" })
  public setCurrentCustomer!: Callback;
  @Action("setOrder", { namespace })
  public setOrder!: Callback;
  @Getter("currentCustomer", { namespace: "CustomerModule" })
  public currentCustomer!: Customer;
  @Getter("loading", { namespace: "OrderModule" })
  public orderLoading!: boolean;
  @Getter("customerLimits", { namespace: "OrderModule" })
  public customerLimits!: RuleConfig[];
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  @Getter("limitConfig", { namespace: "AuthModule" })
  public limitConfig!: LocationSalesLimits[];
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;

  @Prop({ required: true })
  public order!: Order;

  @Prop({ default: () => null })
  public pharmacistSelected!: number | null;
  @Prop()
  public enableLoyalty!: number;
  @Prop()
  public products!: Product[];
  public loadingModal = false;
  public isCheckinLimits = false;
  public sidebar: CartSideBar = {
    status: false,
    type: "",
    component: "",
    orderItem: null
  };
  public discountsPanel: boolean | null = null;
  public policyList = policyList;

  public cartWarnings: {
    limits: string[];
    require_security: boolean;
  } = {
    limits: [],
    require_security: false
  };
  protected expandedItem: number[] = [];
  protected editModalData: {
    product: Product | null;
  } = {
    product: null
  };
  protected salesHourWarning = false;
  protected get customerLimitExceeded() {
    let limitExceed = false;
    this.customerLimits.map(limit => {
      if (Number(limit.current) > Number(limit.amount)) {
        limitExceed = true;
      }
    });
    return limitExceed;
  }

  @Watch("order", { deep: true })
  public orderChange() {
    this.checkCustomersLimit(this.ruleAction);
  }
  @Watch("customerLimits", { deep: true })
  public limitsChange() {
    this.checkCustomersLimit(this.ruleAction);
  }

  get availableCoupon() {
    let coupon = 0;
    if (this.order.customer && this.order.customer.balance) {
      coupon = this.order.customer.balance!.amount;
    }
    return coupon;
  }

  get discountTotal() {
    let totalDiscounts: number = 0;
    if (this.order.discounts!.length) {
      this.order.discounts!.forEach(
        discount => (totalDiscounts += discount.amount!)
      );
    }
    return totalDiscounts;
  }

  get disablePreOrderButton() {
    if (this.order.order_items!.length === 0) {
      return true;
    }
    return this.order.pre_order === null;
  }

  get showPreOrderButton() {
    // this.checkCustomersLimit(this.ruleAction);
    // From a start if the RS in ON the button must be shown
    if (this.isCallInPreOrder) {
      return false;
    }
    if (this.retailSettings.enable_in_store_order_fulfillment_flow) {
      // Except if the pre-order assocated is FILLED and is in sync with the order
      // Or if the pre-order is a CALL_IN pre-order
      if (
        this.order.pre_order &&
        (this.order.pre_order.filled_at !== null &&
          this.order.pre_order.order_in_sync)
      ) {
        return false;
      } else if (
        this.order.pre_order &&
        (this.order.pre_order.filled_at !== null &&
          !this.order.pre_order.order_in_sync)
      ) {
        return true;
      } else if (
        this.order.pre_order &&
        (this.order.pre_order.filled_at !== null &&
          this.order.pre_order.order_in_sync) &&
        this.order.pre_order.source === "CALL_IN"
      ) {
        return false;
      } else if (
        this.order.pre_order &&
        (this.order.pre_order.filled_at !== null &&
          !this.order.pre_order.order_in_sync) &&
        this.order.pre_order.source === "CALL_IN"
      ) {
        return true;
      }
      return true;
    }
    // The RS is OFF so the button must NOT be shown
    return false;
  }

  get showOverRideButton() {
    // this.checkCustomersLimit(this.ruleAction);
    if (this.isCallInPreOrder) {
      return false;
    }
    if (
      this.cartWarnings.limits.length &&
      this.saleHourRuleAction === "block" &&
      !this.showPreOrderButton &&
      !this.isCallInPreOrder &&
      this.salesHourEnabled === 0
    ) {
      if (this.ruleAction === "warning") {
        return true;
      }
      return false;
    }
    if (
      this.cartWarnings.limits.length &&
      this.saleHourRuleAction === "warning" &&
      this.ruleAction === "block" &&
      !this.customerLimitExceeded
    ) {
      if (
        !this.retailSettings.allow_order_to_bypass_in_store_order_fulfillment &&
        this.showPreOrderButton
      ) {
        return false;
      }
      if (!this.order.order_items!.length) {
        return false;
      }
      return true;
    }
    if (
      this.cartWarnings &&
      this.cartWarnings.require_security &&
      this.ruleAction === "warning"
    ) {
      if (this.order.pre_order) {
        if (
          !this.retailSettings.enable_in_store_order_fulfillment_flow &&
          this.order.pre_order.source === "CALL_IN" &&
          this.order.pre_order.filled_at !== null
        ) {
          if (
            this.salesHourEnabled &&
            this.saleHourRuleAction === "block" &&
            this.salesHourWarning
          ) {
            return false;
          }
          return true;
        }
        if (
          this.order.pre_order.filled_at !== null &&
          this.order.pre_order.order_in_sync
        ) {
          if (
            !this.retailSettings
              .allow_order_to_bypass_in_store_order_fulfillment &&
            this.saleHourRuleAction === "block" &&
            this.salesHourWarning
          ) {
            return false;
          }
          if (
            this.retailSettings
              .allow_order_to_bypass_in_store_order_fulfillment &&
            this.saleHourRuleAction === "block" &&
            this.salesHourEnabled &&
            this.salesHourWarning
          ) {
            return false;
          }
          if (
            !this.retailSettings
              .allow_order_to_bypass_in_store_order_fulfillment &&
            this.saleHourRuleAction === "warning" &&
            this.salesHourEnabled
          ) {
            return true;
          }
          return true;
        } else if (
          this.retailSettings.allow_order_to_bypass_in_store_order_fulfillment
        ) {
          if (
            this.order.pre_order.filled_at == null &&
            this.order.pre_order.source === "CALL_IN"
          ) {
            return false;
          }
          if (
            this.saleHourRuleAction === "block" &&
            this.salesHourEnabled &&
            this.salesHourWarning
          ) {
            return false;
          }
          if (!this.order.order_items!.length) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      } else if (
        !this.retailSettings.allow_order_to_bypass_in_store_order_fulfillment &&
        this.saleHourRuleAction === "warning" &&
        this.salesHourEnabled
      ) {
        return true;
      } else if (!this.order.pre_order && this.salesHourEnabled === 1) {
        if (!this.salesHourWarning) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    }
  }

  get showCheckoutButton() {
    if (this.isCallInPreOrder) {
      return false;
    }
    if (
      this.cartWarnings &&
      this.cartWarnings.limits.length > 0 &&
      this.ruleAction === "warning" &&
      this.saleHourRuleAction === "warning"
    ) {
      if (!this.order.order_items!.length && this.salesHourEnabled) {
        return false;
      }
      if (this.order.pre_order && !this.order.order_items!.length) {
        return true;
      }
      if (
        this.salesHourEnabled &&
        this.retailSettings.allow_order_to_bypass_in_store_order_fulfillment &&
        !this.order.order_items!.length
      ) {
        return true;
      }
      return false;
    } else if (
      this.cartWarnings &&
      this.cartWarnings.limits.length > 0 &&
      this.ruleAction === "warning" &&
      this.saleHourRuleAction !== "warning" &&
      this.showOverRideButton === false &&
      !this.showPreOrderButton &&
      !this.isCallInPreOrder
    ) {
      return true;
    } else if (
      this.cartWarnings &&
      this.cartWarnings.limits.length > 0 &&
      this.ruleAction !== "warning" &&
      this.saleHourRuleAction === "warning"
    ) {
      if (this.customerLimitExceeded) {
        if (
          !this.retailSettings
            .allow_order_to_bypass_in_store_order_fulfillment &&
          this.showPreOrderButton
        ) {
          return false;
        }
        return true;
      }
      if (
        this.salesHourEnabled &&
        this.retailSettings.allow_order_to_bypass_in_store_order_fulfillment &&
        !this.order.order_items!.length
      ) {
        return true;
      }
      return false;
    } else if (
      this.cartWarnings &&
      this.cartWarnings.limits.length > 0 &&
      this.ruleAction === "block"
    ) {
      if (this.order.pre_order && this.order.pre_order.filled_at !== null) {
        if (
          this.retailSettings.allow_order_to_bypass_in_store_order_fulfillment
        ) {
          return true;
        } else {
          if (this.order.pre_order && this.order.pre_order.order_in_sync) {
            return true;
          }
          if (
            this.saleHourRuleAction === "block" &&
            this.salesHourEnabled &&
            this.order.pre_order!.source === "CALL_IN" &&
            !this.retailSettings.enable_in_store_order_fulfillment_flow
          ) {
            return true;
          }
        }
      } else if (!this.order.pre_order) {
        return true;
      } else if (
        this.order.pre_order &&
        this.retailSettings.allow_order_to_bypass_in_store_order_fulfillment &&
        this.order.pre_order!.source !== "CALL_IN"
      ) {
        return true;
      }
    } else {
      if (
        // If the RS is OFF and there is no pre-order associated to the order
        (!this.order.pre_order &&
          !this.retailSettings.enable_in_store_order_fulfillment_flow) ||
        // Or there is no pre-order but the RS is ON and the by-pass RS is also ON
        (!this.order.pre_order &&
          this.retailSettings.enable_in_store_order_fulfillment_flow &&
          this.retailSettings.allow_order_to_bypass_in_store_order_fulfillment)
      ) {
        // We show the checkout button
        return true;
      }
      if (
        this.order.pre_order &&
        !this.retailSettings.enable_in_store_order_fulfillment_flow &&
        this.order.pre_order!.source === "CALL_IN" &&
        this.order.pre_order!.filled_at !== null
      ) {
        return true;
      }
      if (
        // If the pre-order assocated is FILLED and is in sync with the order
        this.order.pre_order &&
        this.order.pre_order.filled_at !== null &&
        this.order.pre_order.order_in_sync
      ) {
        // We show the button allowing the user to perform checkout
        return true;
      }
      if (
        // If the RS is ON and the by-pass RS is also ON we display the button
        this.retailSettings.enable_in_store_order_fulfillment_flow &&
        this.retailSettings.allow_order_to_bypass_in_store_order_fulfillment
      ) {
        if (this.order.pre_order && this.order.pre_order.source === "CALL_IN") {
          if (
            this.order.pre_order &&
            this.order.pre_order.filled_at !== null &&
            !this.order.pre_order.order_in_sync
          ) {
            // We show the button allowing the user to perform checkout
            return true;
          } else if (
            this.order.pre_order &&
            this.order.pre_order.filled_at == null &&
            !this.order.pre_order.order_in_sync
          ) {
            // We show the button allowing the user to perform checkout
            return false;
          } else if (
            this.order.pre_order &&
            this.order.pre_order.filled_at == null &&
            this.order.pre_order.order_in_sync
          ) {
            // We show the button allowing the user to perform checkout
            return false;
          }
        }
        if (
          !this.salesHourEnabled &&
          this.customerLimitExceeded &&
          this.ruleAction === "warning"
        ) {
          return false;
        }
        return true;
      }
      // Default behavior is to hide the checkout button
      return false;
    }
  }

  public get ruleAction() {
    let ruleAction = "block";
    this.limitConfig.map(rule => {
      if (
        rule.type === "non_cannabis_product" ||
        rule.type === "cannabis_product"
      ) {
        if (rule.action === "warning") {
          ruleAction = "warning";
        }
      }
    });
    return ruleAction;
  }

  public get saleHourRuleAction() {
    let saleHourRuleAction = "block";
    this.limitConfig.map(rule => {
      if (
        rule.type === "sale_hour" &&
        rule.action === "warning" &&
        rule.enabled
      ) {
        saleHourRuleAction = "warning";
      }
    });
    return saleHourRuleAction;
  }

  public get salesHourEnabled() {
    let salesHourEnabled = 0;
    this.limitConfig.map(rule => {
      if (rule.type === "sale_hour" && rule.enabled) {
        salesHourEnabled = 1;
      }
    });
    return salesHourEnabled;
  }

  public get checkAllCheckoutConditions() {
    // this.checkCustomersLimit(this.ruleAction);

    if (this.cartWarnings.limits.length && this.ruleAction === "block") {
      return true;
    }
    if (
      this.salesHourWarning &&
      this.saleHourRuleAction === "block" &&
      this.salesHourEnabled
    ) {
      return true;
    }

    if (
      ((this.order.order_items && this.order.order_items.length) ||
        (this.cartWarnings &&
          this.cartWarnings.limits &&
          this.cartWarnings.limits.length &&
          !this.cartWarnings.require_security)) &&
      !(this.order.credit && this.order.credit.length)
    ) {
      if (
        this.cartWarnings &&
        this.cartWarnings!.limits &&
        this.cartWarnings!.limits.length &&
        !this.cartWarnings.require_security
      ) {
        return true;
      } else {
        if (this.retailSettings.pharmacy_mode_enabled) {
          return this.checkAllPrescriptionDetail(this.order.order_items || []);
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }
  get disableCheckoutButton() {
    return (
      (!this.order.order_items ||
        !this.order.order_items.length ||
        (this.cartWarnings && !this.cartWarnings.require_security)) &&
      !(this.order.credit && this.order.credit.length)
    );
  }

  private get totalShoot() {
    return this.order.total! - this.order.credit![0].amount;
  }

  public get getMoneyRefound() {
    return (
      (this.order.credit &&
        this.order.credit.length &&
        this.order.credit![0].amount) ||
      0
    );
  }

  public get getTotal() {
    return this.order.credit && this.order.credit!.length
      ? this.totalShoot
      : truncate(this.order.total!, 2);
  }
  public get isCallInPreOrder(): boolean {
    return (
      !!this.order.pre_order &&
      this.order.pre_order.confirmed_at === null &&
      this.order.pre_order.source === "CALL_IN"
    );
  }

  public setQuantity(quantity: number, position: number) {
    this.order.order_items![position].quantity = quantity;
  }

  public sidebarGeneralAction(value: string) {
    this.sidebar = {
      status: !this.sidebar.component
        ? true
        : value === this.sidebar.component
        ? this.sidebar.type === "general"
          ? !this.sidebar.status
          : true
        : true,
      type: "general",
      component: value,
      orderItem: null
    };
  }
  public getLoyaltyDiscountLabel(loyalityItem: LoyalityItem) {
    return orderService.getLoyaltyDiscountLabel(loyalityItem);
  }
  public getDiscountLabel(discount: Discount, addCountable: number) {
    return orderService.getDiscountLabel(discount, addCountable);
  }
  public activeMainButtons(value: string) {
    return (
      this.sidebar.status &&
      this.sidebar.type === "general" &&
      this.sidebar.component === value
    );
  }

  public sidebarItemAction(value: string, orderItem: OrderItem) {
    this.sidebar = {
      status: !this.sidebar.component
        ? true
        : value === this.sidebar.component
        ? this.sidebar.orderItem && this.sidebar.orderItem.id === orderItem.id
          ? this.sidebar.type === "item"
            ? !this.sidebar.status
            : true
          : true
        : true,
      type: "item",
      component: value,
      orderItem
    };
  }

  public activeItemButtons(value: string, orderItemId: number) {
    return (
      this.sidebar.status &&
      this.sidebar.type === "item" &&
      this.sidebar.component === value &&
      this.sidebar.orderItem!.id === orderItemId
    );
  }

  public openExpandedPriceModal(item: OrderItem) {
    this.$modals.load(
      ExpandedPricesComponent,
      {
        title: this.$t("full_prices").toString(),
        size: "fit",
        positionY: "top"
      },
      {
        item
      }
    );
  }

  public inputPincodeOverride() {
    orderService.authorizeOverride().then(
      response => {
        if (this.hasPermission(policyList.overrideSaleLimits)) {
          messagesService.renderSuccessMessage("limit.authorized_override");
          this.$router.push({
            name: "check-out",
            params: { pincode: response }
          });
        } else if (!this.hasPermission(policyList.overrideSaleLimits)) {
          messagesService.renderErrorMessage(this.$t("security_pin.forbidden"));
          return;
        }
      },
      e => {
        if (e && e.response) {
          messagesService.renderErrorMessage(e);
        }
      }
    );
  }

  public async openCompleteCallInModal(): Promise<void> {
    try {
      const isCompleted = await this.$modals.load<boolean>(
        CompleteCallInComponent,
        {
          size: "normal",
          positionY: "center"
        },
        {
          order: this.order
        }
      );
      if (isCompleted) {
        this.setCurrentCustomer(null);
        this.closeSidebar();
        this.createNewOrder();
        this.loadSaleProductsEvent();
        this.cartWarnings = {
          limits: [],
          require_security: false
        };
      }
    } catch (e) {
      // Nothing to do
    }
  }

  public renderTraceabilityId() {
    return this.hasBioTrackTraceIntegrations && this.bioTrackTraceEnabled;
  }

  public goToCheckout() {
    if (
      (this.order.id &&
        this.order.order_items &&
        this.order.order_items.length) ||
      this.order.credit!.length
    ) {
      this.$router.push({ name: "check-out" });
    }
  }

  public checkCustomersLimit(ruleAction: string) {
    if (this.order.customer_id) {
      this.isCheckinLimits = true;

      this.cartWarnings = {
        limits: cloneDeep(
          (this.order.warnings! && this.order.warnings!.limits!) || []
        ),
        require_security: cloneDeep(
          this.order.warnings! && this.order.warnings.require_security
        )
      };

      const limitStrategyIndex = this.customerLimits.findIndex(
        l => String(l) === "multiple_overall_limits"
      );
      this.customerLimits.map(limit => {
        let errorMessage = null;
        if (limitStrategyIndex !== -1) {
          errorMessage = "limit was exceeded by";
        } else {
          errorMessage = "was exceeded by";
        }
        if (Number(limit.current) > Number(limit.amount)) {
          const num = limit.measure === "oz" ? 3 : 2;
          errorMessage = `${limit.name} ${errorMessage} ${parseFloat(
            (+limit!.current! - +limit!.amount!).toFixed(num)
          )} ${limit.measure} .`;

          this.cartWarnings.limits = [
            ...this.cartWarnings.limits,
            errorMessage
          ];
          if (ruleAction === "warning") {
            this.cartWarnings.require_security = true;
          }
        }
      });
      this.isCheckinLimits = false;
    }
  }

  public checkAllPrescriptionDetail(orderItems: OrderItem[]) {
    if (
      this.cartWarnings.limits.length &&
      this.ruleAction === "block" &&
      this.saleHourRuleAction !== "warning"
    ) {
      return true;
    }
    for (const item of orderItems) {
      if (
        item.product_info!.marijuana &&
        !this.availablePrescriptionDetail(item)
      ) {
        return true;
      }
    }
    return false;
  }

  public getUsableWeight(item: OrderItem) {
    return orderService.getUsableWeight(item);
  }

  public cancelSave() {
    this.sidebar.status = false;
    this.sidebar.component = "";
  }

  public save() {
    (async () => {
      // Customer set to Check-in state
      customerService.checkInCustomer(
        String(
          (this.currentCustomer.purchasingBy &&
            this.currentCustomer.purchasingBy.customer_id) ||
            this.currentCustomer.customer_id
        )
      );
      if (
        this.order.id &&
        this.order.order_items &&
        this.order.order_items.length
      ) {
        await orderService.saveOrder(Number(this.order.id));
      }
      this.setCurrentCustomer(null);
      this.sidebar.component = "";
      this.createNewOrder();
      this.loadSaleProductsEvent();
      return this.deleteOrder();
    })().then(this.resetCart);
  }

  public async sendToOrderFulfillment() {
    await this.unloadPreorder();
    // After unloading the order/pre-order we retrieve it with the changes applied
    // Because we need the updated or reverted pre-order items to be printed
    const order = await orderService.findById(this.order.id!, {
      embed: "preOrder"
    });
    if (this.retailSettings.print_pending_in_store_order_receipt_for_customer) {
      EventBus.$emit("print", {
        component: PrintPreOrderPendingReceiptComponent,
        props: { order }
      });
    }
  }
  public async clearOrder() {
    const customer = this.order.customer;
    if (this.order.pre_order) {
      const confirm = await this.deleteOrderWPreOrder();
      if (!confirm) {
        this.closeSidebar();
        return;
      }
      // This new endpoint is supposed to delete the order/pre-order combo
      await orderService.deletePreorder(this.order.order_number!);
    } else {
      await this.deleteOrder();
    }
    this.closeSidebar();
    this.updateCustomerAction(customer);
    this.createNewOrder();
    this.loadSaleProductsEvent();
  }

  public closeSidebar() {
    this.sidebar.status = false;
    this.sidebar.component = "";
  }

  public async deleteOrderItem(item: OrderItem) {
    if (item.has_preorder_item) {
      this.$modals
        .loadConfirmation({
          title: "delete_order_item_warning_title",
          text: "delete_order_item_warning",
          acceptButton: "yes",
          cancelButton: "no"
        })
        .then(async (confirm: boolean) => {
          if (confirm) {
            this.deleteOrderItemAction(item);
            this.loadSaleProductsEvent();
          }
        })
        .catch(() => false);
    } else {
      this.deleteOrderItemAction(item);
      this.loadSaleProductsEvent();
    }
  }

  public loadSaleProductsEvent() {
    this.$emit("loadProducts");
  }

  public availablePrescriptionDetail(item: OrderItem) {
    const prescriptionData = item.prescription_details;
    if (
      prescriptionData &&
      prescriptionData.quantity &&
      prescriptionData.quantity_unit_measure &&
      prescriptionData.timeframe_unit_measure &&
      prescriptionData.dosage_unit_measure &&
      prescriptionData.days_supply &&
      prescriptionData.dosage_take &&
      prescriptionData.dosage_to &&
      prescriptionData.dosage_timeframe_take &&
      prescriptionData.dosage_timeframe_to &&
      prescriptionData.pharmacist_id &&
      prescriptionData.doctor_id
    ) {
      return true;
    } else {
      return false;
    }
  }

  public async prescriptionDetails(item: OrderItem) {
    this.$modals
      .load(
        PosPrescriptionModalComponent,
        {
          size: "fit",
          positionY: "top"
        },
        {
          model: item.prescription_details || defaultPrescriptionDetails,
          orderItem: item,
          onDutyPharmacistSelected: this.pharmacistSelected,
          pageType: "orderPage",
          disabled: !this.hasPermission(
            policyList.modifyPrescriptionDetailsInPos
          )
        }
      )
      .catch(() => {
        // nothing to do
      });
  }

  public async editItem(item: OrderItem) {
    this.loadingModal = true;
    // Temporary: A new endpoint will be provided for this
    const product = await productService.findBySkuId(getParentSKU(item.sku!), {
      embed: "priceSettings,batches.sample"
    });

    this.loadingModal = false;
    this.$modals
      .load<AddToCartPayload>(
        PosProductModalComponent,
        {
          size: "normal",
          positionY: "top"
        },
        {
          position: 0,
          product: await productService.setExpandedProductView(product, item),
          pharmacistSelected: this.pharmacistSelected
        }
      )
      .then((data: AddToCartPayload) => this.$emit("editItem", data))
      .catch(() => {
        // No action on close.
      });
  }

  public removeDiscount(discountItem: Discount, itemId?: number) {
    if (discountItem.is_whole_order_discount === 1) {
      this.removeWholeOrderDiscount(discountItem);
      return;
    }
    const discount: { [key: string]: any } = {
      discount_id: discountItem.discount_id,
      apply_type: discountItem.apply_type,
      original_discount_amount: discountItem.original_discount_amount
    };
    if (itemId) {
      discount.item_id = itemId;
    }
    if (discountItem.auto_apply) {
      this.removeAutoApplyDiscount({
        discount
      });
    } else {
      if (!discountItem.apply_type) {
        delete discount.apply_type;
      }
      this.removeDiscountAction({
        discount
      });
    }
  }
  // Function to handle the loyalityPoints products and transform it into a standard item
  public async removeLoyality(item: OrderItem) {
    // This is a custom payload to handle multiple orderItems
    const data: AddToCartPayload = {
      orderItem: [
        {
          order_item_uid: item.order_item_uid,
          _destroy: true
        },
        {
          // IMPORTANT: the order_item_uid MUST be in null to be added as a new item
          order_item_uid: null,
          sku: item.sku || item.batch_sku!,
          batch_barcode_uid: item.batch_barcode_uid,
          quantity: item.quantity
        }
      ],
      loyalty_program_tier_id: null
    };
    this.$emit("editItem", data);
  }

  public addOrderItem(item: AddToCartPayload) {
    this.$emit("addItem", item);
  }

  public appliedDiscountItem(id: number) {
    return (
      (this.order.applied_discounts &&
        this.order.applied_discounts.filter(d => d.item_id === id)) ||
      []
    );
  }

  public priceFinalAlert(item: OrderItem) {
    const discountsItem = this.appliedDiscountItem(item.id!);
    let totalDiscount = 0;
    discountsItem.map(disc => {
      if (disc.apply_type === "PERCENTAGE") {
        if (
          !disc.whole_order_unique_id ||
          disc.whole_order_unique_id === null
        ) {
          const discount = (Number(item.price_raw) * Number(disc.amount)) / 100;
          totalDiscount += discount;
        } else {
          totalDiscount += Number(disc.amount);
        }
      } else {
        totalDiscount += Number(disc.amount);
      }
    });
    return (
      item.product_info!.block_item_from_being_sold_at_zero_cost! &&
      discountsItem.length &&
      item.price_raw! - totalDiscount <= 0
    );
  }

  public priceUnitAlert(item: OrderItem) {
    const discountsItem = this.appliedDiscountItem(item.id!);
    let totalDiscount = 0;
    discountsItem.map(disc => {
      if (disc.apply_type === "PERCENTAGE") {
        const discountAmount =
          !disc.whole_order_unique_id || disc.whole_order_unique_id === null
            ? disc.amount
            : disc.whole_order_discount_applied;
        const discount =
          (Number(item.price_raw) * Number(discountAmount)) / 100;
        totalDiscount += discount;
      } else {
        totalDiscount += Number(disc.amount);
      }
    });
    return (
      item.product_info!.block_item_from_being_sold_below_cost_per_unit! &&
      discountsItem.length &&
      item.price_raw! - totalDiscount < +item.product_info!.cost_per_unit!
    );
  }

  public unloadPreorder() {
    if (
      this.order.order_items &&
      this.order.pre_order &&
      !this.order.pre_order.order_in_sync &&
      // The only case where we can have a pre-order with NO items is upon creation
      this.order.pre_order.items.length
    ) {
      // show modal
      return this.$modals
        .load(PreOrderSaveModalComponent, {
          size: "fit",
          positionX: "center",
          positionY: "center"
        })
        .then(
          data => {
            if ((data as unknown) === "original") {
              orderService
                .unloadPreorder(this.order.pre_order!.uid, false)
                .then(this.resetCart);
            } else if ((data as unknown) === "update") {
              orderService
                .unloadPreorder(this.order.pre_order!.uid, true)
                .then(this.resetCart);
            }
          },
          () => this.closeSidebar
        );
    } else {
      orderService
        .unloadPreorder(this.order.pre_order!.uid)
        .then(this.resetCart);
    }
  }

  public warningsMessage(message: string) {
    if (message.includes("sales_hour_limit")) {
      const saleHour = this.limitConfig.find(item => item.type === "sale_hour");
      const config = saleHour!.configs.find(
        configItem => configItem.config.type === "hours"
      );
      const fromTime = fnsFormatDate(
        UTCTimeToLocalDate(config!.config.from),
        FNS_DATE_FORMATS.LT
      );

      const toTime = fnsFormatDate(
        UTCTimeToLocalDate(config!.config.to),
        FNS_DATE_FORMATS.LT
      );
      const salesHourLimitMessage = `${fromTime} - ${toTime}`;
      const warningMessage =
        message && message.replace("sales_hour_limit", salesHourLimitMessage);
      this.salesHourWarning = true;
      return warningMessage;
    }
    return message;
  }

  protected resetCart() {
    // Customer set to Check-in state
    customerService.checkInCustomer(
      (this.currentCustomer.purchasingBy &&
        this.currentCustomer.purchasingBy.customer_id) ||
        this.currentCustomer.customer_id!
    );

    this.setCurrentCustomer(null);
    this.closeSidebar();
    this.createNewOrder();
    this.loadSaleProductsEvent();
    this.cartWarnings = {
      limits: [],
      require_security: false
    };
  }

  protected async deleteOrderWPreOrder() {
    const modalData = {
      title: "",
      acceptButton: "yes",
      cancelButton: "no",
      text: "pos_order_preoder_delete"
    };
    const choice = await this.$modals.loadConfirmation(modalData, {
      positionX: "center",
      size: "fit"
    });
    return choice;
  }
}
