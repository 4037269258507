import Template from "@/components/retail/pos/pointOfSale/posCart/Loyalty/Lines/Lines.template.vue";
import {
  LoyaltyProgram,
  LoyaltyProgramTier
} from "@/interfaces/loyaltyProgram";
import { AddToCartPayload, Order, OrderItem } from "@/interfaces/order";
import { LoyaltyConfig } from "@/vuex/modules/order/order.types";
import Cookie from "js-cookie";
import cloneDeep from "lodash/cloneDeep";
import sortBy from "lodash/sortBy";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
const namespace: string = "OrderModule";

@Component({
  mixins: [Template]
})
export default class LinesComponent extends Vue {
  @Prop({ required: true })
  public orderItem!: OrderItem;
  @Getter("loyaltyConfig", { namespace })
  public loyaltyConfig!: LoyaltyConfig;
  public amountStrings: {} = {};
  public loyaltyTotals!: {};
  public linePrograms: LoyaltyProgram[] = [];
  public programs: LoyaltyProgram[] = [];
  public orderItemCopy: OrderItem = cloneDeep(this.orderItem);

  public mounted() {
    const order = cloneDeep(this.orderItem);
    const productName = order.product_info!.name;
    this.programs = cloneDeep(this.loyaltyConfig.programs);
    this.setLinePrograms(this.programs, productName);
    this.amountStrings = {
      NUMBER_OF_VISITS: this.$t("customer.visits"),
      LOYALTY_POINTS: this.$t("customer.points")
    };
  }
  public setLinePrograms(loyaltyProgram: any, productName: string) {
    this.loyaltyTotals = this.loyaltyConfig.total_points;
    const programData =
      loyaltyProgram &&
      loyaltyProgram.filter((program: LoyaltyProgram) => {
        const rewards = this.getCurrentRewards(program);
        return rewards && rewards.length;
      });
    const filteredPrograms = programData.map((item: LoyaltyProgram) => {
      return {
        ...item,
        program_tiers: item.program_tiers!.filter(prod => {
          return prod.product!.name === productName;
        })
      };
    });

    this.linePrograms = filteredPrograms.filter((item: LoyaltyProgram) => {
      return item.program_tiers!.length !== 0;
    });
  }
  public getCurrentRewards(program: LoyaltyProgram) {
    if (!program.program_tiers) {
      return;
    }
    let programTiers: LoyaltyProgramTier[];
    if (!Array.isArray(program.program_tiers)) {
      const allKeysNumbers = Object.keys(program.program_tiers).every(
        key => !Number.isNaN(parseInt(key, 10))
      );
      if (allKeysNumbers) {
        programTiers = Object.values(program.program_tiers);
      } else {
        return;
      }
    } else {
      programTiers = program.program_tiers;
    }

    return sortBy(
      programTiers.filter((tier: LoyaltyProgramTier) => {
        return tier.apply;
      }),
      ["based_on_value"]
    );
  }
  public async addItemToOrder(tier: LoyaltyProgramTier) {
    const assignedPharmacistUserPayload = Cookie.get("pos_pharmacist") || null;
    const pharmacistSelected =
      assignedPharmacistUserPayload &&
      assignedPharmacistUserPayload !== "undefined"
        ? Number(assignedPharmacistUserPayload)
        : 0;
    this.orderItem = {
      ...this.orderItem,
      pharmacist_id: pharmacistSelected
    };
    const item: AddToCartPayload = {
      orderItem: [this.orderItemCopy],
      loyalty_program_tier_id: tier.id || null
    };
    this.$emit("addItem", item);
    this.$emit("closePoints", item);
  }
}
