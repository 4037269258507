import { TablePagination } from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./posCardsPlaceholder.template.vue";

const defaultPagination = {
  totalItems: 0,
  itemsPerPage: 10,
  itemsPerPageOptions: [5, 10, 20, 50],
  currentPage: 1,
  lastPage: 0
};
@Component({
  mixins: [Template]
})
export default class PosCardsPlaceholderComponent extends Vue {
  @Prop({ default: defaultPagination }) public pagination!: TablePagination;
}
