import { DiscountValues } from "@/enums/discountManager";
import {
  AppliedDiscount,
  ManualDiscounts,
  OrderItem,
  overrideDiscount,
  OverrideDiscount
} from "@/interfaces/order";
import { RetailSettings } from "@/interfaces/retailSettings";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import { messagesService } from "@/services/messages.service";
import { CallbackPromise } from "@/types/types";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./Override.template.vue";
const namespace: string = "OrderModule";

@Component({
  mixins: [Template]
})
export default class OverrideComponent extends Vue {
  @Prop({ required: true }) public orderItem!: OrderItem;
  @Prop({ required: true }) public manualDiscounts!: ManualDiscounts;
  @Prop({ required: true }) public orderDiscounts!: AppliedDiscount[];
  public model: OverrideDiscount = cloneDeep(overrideDiscount);
  public applyTypes: Array<{ name: string; value: DiscountValues }> = [];
  @Action("addDiscount", { namespace })
  public addDiscountAction!: CallbackPromise<void>;
  public applyType: "FIXED" | "PERCENTAGE" = "FIXED";
  public isEdit = false;
  public isValid = false;
  public pinService!: SecurityPinService;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  protected retailSettings!: RetailSettings;

  public mounted() {
    this.applyTypes = [
      {
        name: "%",
        value: DiscountValues.PERCENTAGE
      },
      {
        name: "$",
        value: DiscountValues.FIXED
      }
    ];
    this.checkForExistent();
    this.pinService = new SecurityPinService();
  }

  public checkForExistent() {
    this.isEdit = false;
    this.model = cloneDeep(overrideDiscount);
    this.model.post_tax = !this.retailSettings!.retail_pre_tax_pricing;
  }

  public async addDiscount() {
    if (await this.$validator.validate()) {
      try {
        const pin = await this.pinService.ensure(
          this.$t("security_pin.title").toString(),
          this.$t("cart_discounts.pin_text").toString()
        );
        if (this.orderItem) {
          this.model.discount_id =
            this.applyType === "FIXED"
              ? this.manualDiscounts.item.fixed
              : this.manualDiscounts.item.percentage;
          this.model.item_id = this.orderItem.id!;
        } else {
          this.model.discount_id =
            this.applyType === "FIXED"
              ? this.manualDiscounts.order.fixed
              : this.manualDiscounts.order.percentage;
          delete this.model.item_id;
        }

        this.addDiscountAction({
          discount: [this.model],
          pin,
          isEdit: this.isEdit
        }).then(() => this.$emit("resolve", null));
      } catch (e) {
        messagesService.renderWarningMessage("required_pin");
      }
    }
  }
}
