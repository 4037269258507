import PosImageComponent from "@/components/retail/pos/pointOfSale/posImage/PosImage.component";
import PosSelectorsComponent from "@/components/retail/pos/pointOfSale/posSelectors/PosSelectors.component";
import { EventBus } from "@/event-bus";
import { currencyFilter } from "@/filters/currency.filter";
import {
  Batch,
  InventoryBatch,
  LaboratoryResult,
  StockSummary
} from "@/interfaces/batch";
import { Customer } from "@/interfaces/customer";
import { Location } from "@/interfaces/location";
import {
  defaultProgram,
  LoyaltyProgramModalProduct
} from "@/interfaces/loyaltyProgram";
import { AddToCartPayload } from "@/interfaces/order";
import {
  defaultPrescriptionDetails,
  PrescriptionDetails
} from "@/interfaces/prescriptionDetails";
import { Pricebreak, Product, SampleResult } from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import { StrainNote } from "@/interfaces/strain";
import { i18n } from "@/plugins/i18n";
import { labResultsService } from "@/services/labResults.service";
import { productService } from "@/services/product.service";
import { strainService } from "@/services/strain.service";
import groupBy from "lodash/groupBy";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import orderBy from "lodash/orderBy";
import toArray from "lodash/toArray";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./PosProductModal.template.vue";

const stringResults = (gramVal: number, perVal: number) => {
  const results = [];
  if (gramVal) {
    results.push(`${gramVal} mg/g`);
  }
  if (perVal) {
    results.push(`${perVal}%`);
  }
  if (results.length) {
    return results.join(" - ");
  }
  return 0;
};
@Component({
  mixins: [Template],
  components: {
    PosSelectorsComponent,
    PosImageComponent
  },
  filters: { currencyFilter }
})
export default class PosProductModalComponent extends Vue {
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  @Getter("currentCustomer", { namespace: "CustomerModule" })
  public currentCustomer!: Customer;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  public i = 0;
  public currentTab = "";
  public strainNotesList: StrainNote[] = [];
  public batchPricePoint: { prices: Pricebreak[]; unitPriceUsed: string } = {
    prices: [],
    unitPriceUsed: "--"
  };
  public showRange: boolean = true;
  public batch: InventoryBatch | null = null;
  public price: number | null = null;
  public stockSummary: StockSummary[] = [];
  public loadingStockSummary = false;
  public loadingPrices = false;
  public loadingSample = false;
  @Prop({ default: () => null })
  public pharmacistSelected!: number | null;
  @Prop({ required: true }) public product!: Product;
  @Prop({ default: () => ({ ...defaultProgram }) })
  public programs!: LoyaltyProgramModalProduct;
  public sampleList: { [key: string]: LaboratoryResult[] } = {
    POTENCY: [],
    TERPENE: []
  };
  public prescriptionDetail: PrescriptionDetails = defaultPrescriptionDetails;
  public loadingBatch(loading: boolean) {
    this.loadingPrices = loading;
  }

  public async getLaboratoyResults(): Promise<{
    [key: string]: LaboratoryResult[];
  }> {
    return this.batch
      ? await this.formatSamplesBatch()
      : await this.formatSampleProduct();
  }
  public get quantityAvailable() {
    return {
      quantity:
        this.programs.rewardMax ||
        (this.batch &&
          this.batch!.summary.reduce((acc, sumItem) => {
            if (sumItem.batch_fraction_status_type === "AVAILABLE") {
              acc += sumItem.quantity_value;
            }
            return acc;
          }, 0)) ||
        this.product.total_quantity
    };
  }

  public get bathIndexOnProduct() {
    if (!this.batch) {
      return -1;
    }
    return this.product.batches.findIndex(
      (batch: Batch | InventoryBatch) =>
        batch.batch_uid === this.batch!.batch_uid
    );
  }

  public get imagePriority(): Array<{
    priority: number;
    type: string;
  }> {
    return orderBy(
      [
        {
          priority: this.currentRetailSettings.product_images_priority!,
          type: "product"
        },
        {
          priority: this.currentRetailSettings.brand_images_priority!,
          type: "brand"
        },
        {
          priority: this.currentRetailSettings.strain_images_priority!,
          type: "strain"
        }
      ],
      ["priority"],
      ["asc"]
    );
  }

  public get buttonLabel() {
    return this.product.orderItem!.id ? "edit_item" : "add_to_cart";
  }
  public totalLabs(profile: string) {
    const sample: { [key: string]: any } | null =
      this.batch && this.batch.sample!;
    return this.batch
      ? (sample &&
          stringResults(sample[profile], sample[`${profile}_relative`]!)) ||
          this.$t("no_lab_results")
      : "--";
  }

  public async changeBatch(batchPricePoint: {
    batch: InventoryBatch;
    prices: Pricebreak[];
    unitPriceUsed: string;
  }) {
    this.batch = batchPricePoint.batch;
    this.batchPricePoint.prices = batchPricePoint.prices;
    this.batchPricePoint.unitPriceUsed = batchPricePoint.unitPriceUsed;
    this.setSampleResult();
    this.showRange = false;
  }

  public addItemToCart(item: AddToCartPayload) {
    this.$emit("resolve", item);
  }

  public samplesResultsProduct() {
    const convert = (result: SampleResult) =>
      result.unit === "mg/g" ? +result.value! / 10 : result.value;
    const labs = (this.product.batches as InventoryBatch[])
      .reduce((acc: LaboratoryResult[][], b) => {
        if (b.sample!.results) {
          acc.push(b.sample!.results);
        }
        return acc;
      }, [])
      .flat();
    const formated = groupBy(
      productService.samplesResultFormatted(labs),
      "type"
    );
    return toArray(formated).map((result: any) => {
      const max = maxBy(result, convert);
      const min = minBy(result, convert);
      return {
        ...result[0],
        maxValue: max!.value || 0,
        minValue: min!.value || 0,
        unitMax: max!.unit,
        unitMin: min!.unit
      };
    });
  }

  public async setSampleResult() {
    if (this.currentTab === "LABORATORY") {
      this.loadingSample = true;
      this.sampleList = await this.getLaboratoyResults();
      this.loadingSample = false;
    }
  }

  protected mounted() {
    if (this.product.orderItem) {
      this.batch = this.product.orderItem.batch || null;
    }
    this.showRange = false;
    if (this.product.strain_id) {
      strainService.getStrain(this.product.strain_id).then(strain => {
        this.strainNotesList =
          (strain.notes &&
            strain.notes.filter(note => note.shows_note_at_point_of_sale)) ||
          [];
      });
    }
    EventBus.$on("changePrice", (price: number) => {
      this.price = price;
    });
    this.loadingStockSummary = true;
    productService
      .stockSummary(this.product.batches as InventoryBatch[])
      .then(response => {
        this.stockSummary = response;
        this.loadingStockSummary = false;
      });
    this.batchPricePoint.prices =
      (this.product.orderItem && this.product.orderItem.prices) || [];
  }

  private async formatSamplesBatch() {
    const batchIndex = (this.product.batches as InventoryBatch[]).findIndex(
      batch => !!this.batch && batch.batch_uid === this.batch.batch_uid
    );
    if (!this.product.batches[batchIndex].sample) {
      return { POTENCY: [], TERPENE: [] };
    }
    if (batchIndex > -1 && !this.product.batches[batchIndex].sample!.results) {
      const currentSample = await labResultsService.getBatchSamples(
        this.batch!.batch_uid
      );
      if (currentSample) {
        this.product.batches[batchIndex].sample!.results =
          currentSample.results || [];
      }
    }
    return (
      (this.product.batches[batchIndex].sample!.results &&
        groupBy(
          productService.samplesResultFormatted(
            this.product.batches[batchIndex].sample!.results
          ),
          "breakdown"
        )) || { POTENCY: [], TERPENE: [] }
    );
  }

  private percentageMLP() {
    if (this.currentCustomer && this.currentCustomer.member_level) {
      let pricepoint;
      if (this.product.pricing) {
        pricepoint = this.product.pricing.find(
          code =>
            code.member_level_code === this.currentCustomer.member_level!.code
        );
      }
      if (
        this.product.price_settings.member_discount_eligible &&
        this.currentCustomer.member_level!.type === "PERCENTAGE"
      ) {
        return i18n
          .t("mlp_percentage_message", {
            mlp_name: this.currentCustomer.member_level!.name,
            mlp_rate: this.currentCustomer.member_level!.rate
          })
          .toString();
      } else if (
        this.product.price_settings.member_discount_eligible &&
        this.currentCustomer.member_level!.type === "PRICE_POINT" &&
        this.currentCustomer.member_level!.automatic_percentage_fallback &&
        !pricepoint
      ) {
        return i18n
          .t("mlp_percentage_message", {
            mlp_name: this.currentCustomer.member_level!.name,
            mlp_rate: this.currentCustomer.member_level!.rate
          })
          .toString();
      }
    }
  }

  private async formatSampleProduct() {
    this.product.batches = await Promise.all(
      (this.product.batches as InventoryBatch[]).map(async batch => {
        let sampleResult = null;
        if (!batch.sample!.results) {
          sampleResult = await labResultsService.getBatchSamples(
            batch.batch_uid
          );
        }

        return {
          ...batch,
          sample: {
            ...batch.sample,
            results:
              (sampleResult &&
                !batch.sample!.results &&
                sampleResult.results) ||
              batch.sample!.results
          }
        } as InventoryBatch;
      })
    );
    return groupBy(this.samplesResultsProduct(), "breakdown");
  }
}
