import ExpandedPriceTooltip from "@/components/retail/pos/pointOfSale/posCart/ExpandedPriceTooltip/ExpandedPriceTooltip.component";
import { EventBus } from "@/event-bus";
import { InventoryBatch, ProductVariantModel } from "@/interfaces/batch";
import { Customer } from "@/interfaces/customer";
import { Location } from "@/interfaces/location";
import { MemberLevel } from "@/interfaces/memberLevel";
import { AddToCartPayload, OrderItem } from "@/interfaces/order";
import {
  defaultPrescriptionDetails,
  PrescriptionDetails
} from "@/interfaces/prescriptionDetails";
import { Pricebreak, PricingRule } from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import { batchService } from "@/services/batch.service";
import { batchLevelService } from "@/services/batchLevel.service";
import { productService } from "@/services/product.service";
import { truncate } from "@/utils/math.utils";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import maxBy from "lodash/maxBy";
import min from "lodash/min";
import minBy from "lodash/minBy";
import sortBy from "lodash/sortBy";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./PosSelectors.template.vue";

@Component({
  mixins: [Template],
  components: {
    ExpandedPriceTooltip
  }
})
export default class PosSelectorsComponent extends Vue {
  public get getItem() {
    return { base_price: this.orderItemCopy.payment };
  }

  get buttonLabel() {
    return this.orderItemCopy.id ? "edit_item" : "add_to_cart";
  }
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  @Getter("currentCustomer", { namespace: "CustomerModule" })
  public currentCustomer!: Customer;
  @Getter("memberLevels", { namespace: "MemberLevelModule" })
  public memberLevels!: MemberLevel[];
  @Prop({ required: true }) public batches!: InventoryBatch[];
  @Prop({ required: true }) public orderItem!: OrderItem;
  @Prop({ default: () => defaultPrescriptionDetails })
  public prescriptionDetail!: PrescriptionDetails;
  @Prop({ required: true }) public direction!: string;
  @Prop() public loyaltyProgramTierId!: number;
  @Prop() public rewardMax!: number;
  @Prop() public rewardPrice!: number;
  @Prop({ required: true }) public unit!: string;
  @Prop({ required: true }) public unitPayment!: string;
  @Prop({ required: true, default: 0 }) public maxSalesflorQty!: number;
  @Prop({ required: true, default: 1 }) public productWeight!: number;
  @Prop({ default: false }) public loading!: boolean;
  @Prop({ default: "" }) public ndcNumber!: string;
  @Prop({ default: () => null })
  public pharmacistSelected!: number | null;

  public batchSelectedId: string | null = null;
  public disabled: boolean = true;
  public classSelected: string = "vertical";
  public orderItemCopy: OrderItem = cloneDeep(this.orderItem);
  public available: number = 1;
  public disableSelector: boolean = false;
  public usableWeight: number = 1;
  public batchSelected?: InventoryBatch;
  public batchesList: InventoryBatch[] = [...this.batches];
  public batchCurrentPricing: { pricing: PricingRule[] } | null = null;
  public step = this.unit === "u" ? 1 : 0.01;
  public productVariant: ProductVariantModel | null = null;
  public showItem: string = "";

  public get hasDirtyTable() {
    return (
      (this.batchCurrentPricing &&
        this.batchCurrentPricing!.pricing.reduce(
          (acc: number[], p: PricingRule) => {
            if (
              p.price_breaks &&
              p.pricing_type === "RETAIL_MEDICAL" &&
              p.member_level_name === "Normal"
            ) {
              const priceBreaks = p.price_breaks;
              const pBPerUnit = priceBreaks
                .sort((current, next) => {
                  return current.quantity - next.quantity;
                })
                .map(pBreak => {
                  return pBreak.price / (pBreak.quantity * this.usableWeight);
                });
              acc = [...acc, ...pBPerUnit];
            } else if (
              p.price_breaks &&
              p.pricing_type === "RETAIL_MEDICAL" &&
              p.member_level_code === this.getCustomerCurrentMemberLevel
            ) {
              const priceBreaks = p.price_breaks;
              const pBPerUnit = priceBreaks
                .sort((current, next) => {
                  return current.quantity - next.quantity;
                })
                .map(pBreak => {
                  return pBreak.price / (pBreak.quantity * this.usableWeight);
                });
              acc = [...pBPerUnit];
            }
            return acc;
          },
          []
        ).some((pricePerUnit, pos, priceArr) => {
          const prevPUU = priceArr[pos - 1] || Infinity;
          return (
            Math.round(pricePerUnit * 100) / 100 >
            Math.round(prevPUU * 100) / 100
          );
        })) ||
      false
    );
  }

  public get paymentDisabled() {
    return this.disabled || this.hasDirtyTable;
  }

  public bouncePayment = debounce(() => {
    if (this.orderItemCopy.payment) {
      this.calculateQuantity();
      if (
        this.unit === "u" &&
        this.orderItemCopy.unit &&
        this.orderItemCopy.quantity &&
        +this.orderItemCopy.quantity % 1 !== 0
      ) {
        this.orderItemCopy.quantity = Math.floor(
          Number(this.orderItemCopy.quantity)
        );
      }
      this.calculatePayment();
    }
  }, 750);

  public renderTraceabilityId() {
    return this.hasBioTrackTraceIntegrations && this.bioTrackTraceEnabled;
  }

  public async mounted() {
    this.available = this.rewardMax || +this.maxSalesflorQty;
    if (this.orderItemCopy) {
      if (this.orderItemCopy.batch_uid) {
        this.batchSelectedId = this.orderItemCopy.batch_uid!;
        this.disableSelector = true;
      } else {
        if (this.currentRetailSettings.oldest_batch_first) {
          this.batchesList = sortBy(this.batches, "created_at");
        }
        this.disableSelector = this.batchesList.length === 1;
        this.batchSelectedId =
          this.currentRetailSettings.oldest_batch_first ||
          this.batchesList.length === 1
            ? this.batchesList[0].batch_uid
            : null;
        if (!this.batchSelectedId && this.orderItemCopy.batch_barcode_uid) {
          const batchIndex = this.batchesList.findIndex(
            batch => batch.batch_uid === this.orderItemCopy.batch_barcode_uid
          );
          if (batchIndex !== -1) {
            this.batchSelectedId = this.orderItemCopy.batch_barcode_uid;
          }
        }
        this.orderItemCopy.payment =
          (this.batchSelectedId && this.orderItemCopy.payment) || 0;
      }
      this.classSelected = this.direction;
    }
    await this.selectBatch();
    if (
      this.orderItemCopy.price_point_by_usable_weight ||
      (this.productVariant &&
        !this.productVariant!.requires_weighing &&
        this.batchCurrentPricing!.pricing[0].pricing_style === "units")
    ) {
      // countable products with units Pricing Style
      this.usableWeight = 1;
    } else if (
      this.orderItemCopy.price_point_by_usable_weight ||
      (this.productVariant &&
        !this.productVariant!.requires_weighing &&
        this.batchCurrentPricing!.pricing[0].pricing_style === "weight" &&
        this.productVariant!.usable_weight_unit)
    ) {
      // countable products with weight (mg & oz) Pricing Style
      const weightValue = this.productVariant!.usable_weight_value || 0;
      const weightUnit = this.productVariant!.usable_weight_unit;
      if (weightUnit === "mg") {
        this.usableWeight = weightValue / 1000;
      } else if (weightUnit === "oz") {
        this.usableWeight = weightValue * 28.35;
      }
    } else {
      this.usableWeight =
        this.orderItemCopy.price_point_by_usable_weight ||
        (this.productVariant && this.productVariant.usable_weight_value) ||
        // this.productWeight || (This line is commented because it has same fallback value as productVariant.usable_weight_value but keeping this for further reference,can be removed after complete product testing)
        1;
    }
    this.orderItemCopy.currentPrice = this.selectPrice(
      this.orderItemCopy.quantity! * this.usableWeight
    );
    this.orderItemCopy.payment = this.orderItemCopy.currentPrice;
    if (
      this.currentRetailSettings &&
      this.currentRetailSettings.stock_identification_type_in_pos ===
        "NDC_NUMBER"
    ) {
      this.showItem = this.ndcNumber;
    }
  }
  public get additionInfoIdentification() {
    return (
      this.currentRetailSettings &&
      this.currentRetailSettings.stock_identification_type_in_pos
    );
  }

  public changeDisable() {
    this.disabled = this.orderItemCopy.batch_uid ? false : true;
    if (typeof this.orderItemCopy.quantity === "string") {
      const quantity: string = Number(this.orderItemCopy.quantity!).toFixed(2);
      this.orderItemCopy.quantity = this.orderItemCopy.unit
        ? Math.floor(Number(quantity))
        : +Number(quantity).toFixed(2);
      if (this.orderItemCopy.quantity! > this.available) {
        this.orderItemCopy.quantity! = this.available;
      }
      this.calculatePayment();
    }

    if (typeof this.orderItemCopy.payment === "string") {
      const payment: string = Number(this.orderItemCopy.payment).toFixed(2);
      this.orderItemCopy.payment = Number(payment);
      const maxPrice: number =
        this.available *
        this.orderItemCopy.prices![this.orderItemCopy.prices!.length - 1]
          .price!;
      if (this.orderItemCopy.payment! > maxPrice) {
        this.orderItemCopy.payment! = maxPrice;
        this.orderItemCopy.quantity! = this.available;
        this.calculatePayment();
      } else {
        this.calculateQuantity();
      }
    }
  }

  private get getCustomerCurrentMemberLevel() {
    if (
      this.currentCustomer &&
      this.currentCustomer.member_level &&
      this.memberLevels
    ) {
      const level = this.memberLevels.find(
        ml => ml.id === this.currentCustomer.member_level!.id
      );
      return level && level.code;
    }
  }

  public async selectBatch() {
    if (this.batchSelectedId) {
      this.orderItemCopy.batch_uid! = this.batchSelectedId;
      const batch: InventoryBatch = this.batchesList.find(
        batchItem => batchItem.batch_uid === this.batchSelectedId
      )!;
      this.orderItemCopy.batch_barcode_uid = batch.batch_uid;
      this.orderItemCopy.batch_sku = batch.sku;
      this.$emit("loadingBatch", true);
      this.batchCurrentPricing = await batchLevelService.getBatchPrices(
        batch.sku
      );
      this.productVariant = await batchService.getProductVariant(batch.sku);
      const orderedPrices = (this.currentCustomer &&
        productService.getLocationPricingRule(
          {
            pricing: this.batchCurrentPricing!.pricing
          },
          this.getCustomerCurrentMemberLevel
        )) || {
        price_breaks: this.batchCurrentPricing!.pricing.reduce(
          (acc: Pricebreak[], p: PricingRule) => {
            if (p.pricing_type === this.currentLocation.location_type) {
              const priceBreak =
                (p.price_breaks &&
                  p.price_breaks.map(pb => ({
                    ...pb,
                    member_level_name: p.member_level_name
                  }))) ||
                [];
              acc = [...acc, ...priceBreak];
            }
            return acc;
          },
          []
        )
      };
      const normalPriceBreak = {
        price_breaks: this.batchCurrentPricing!.pricing.reduce(
          (acc: Pricebreak[], memberLevelPricing: PricingRule) => {
            if (
              memberLevelPricing.pricing_type ===
                this.currentLocation.location_type &&
              memberLevelPricing.member_level_code === null
            ) {
              const priceBreaks =
                (memberLevelPricing.price_breaks &&
                  memberLevelPricing.price_breaks.map(pricebreak => ({
                    ...pricebreak,
                    member_level_name: memberLevelPricing.member_level_name
                  }))) ||
                [];
              acc = [...acc, ...priceBreaks];
            }
            return acc;
          },
          []
        ).sort((priceBreak1: Pricebreak, priceBreak2: Pricebreak) => {
          return priceBreak2.quantity - priceBreak1.quantity;
        })
      };

      this.batchSelected = batch;
      if (
        this.orderItemCopy.unit &&
        this.orderItemCopy.price_point_by_usable_weight
      ) {
        this.usableWeight =
          (this.productVariant && this.productVariant.usable_weight_value) ||
          this.orderItemCopy.usable_weight_value ||
          1;
      }
      this.orderItemCopy.prices = (orderedPrices.price_breaks || [])
        .sort((a: Pricebreak, b: Pricebreak) => {
          return b.quantity - a.quantity;
        })
        .reduce((acc: Pricebreak[], p) => {
          if (this.currentCustomer) {
            p.member_level_name = orderedPrices.member_level_name!;
            if (orderedPrices.member_level_code && !acc.length) {
              normalPriceBreak.price_breaks.map(npb => {
                acc.push(npb);
              });
            }
          }
          acc.push(p);
          return acc;
        }, [])
        .sort((a: Pricebreak, b: Pricebreak) =>
          a.member_level_name! > b.member_level_name! ? 1 : -1
        );
      this.orderItemCopy.batch_barcode_uid = batch.batch_uid;
      this.available = batch.in_store_quantity_value || 0;
      this.$emit("selectedBatch", {
        batch,
        prices: this.orderItemCopy.prices,
        unitPriceUsed:
          this.batchCurrentPricing!.pricing[0].pricing_style === "units"
            ? "u"
            : "g"
      });
      if (this.rewardPrice) {
        this.orderItemCopy.payment = this.rewardPrice;
        this.orderItemCopy.currentPrice = this.rewardPrice;
      } else {
        this.calculatePayment();
      }
      this.changeDisable();
    }
    this.$emit("loadingBatch", false);
  }

  public decrementQuantity() {
    if (this.orderItemCopy.quantity! > this.step) {
      this.orderItemCopy.quantity! = Number(
        (+this.orderItemCopy.quantity! - this.step).toFixed(3)
      );
      this.calculatePayment();
    }
  }

  public incrementQuantity() {
    if (this.orderItemCopy.quantity! > this.available) {
      this.orderItemCopy.quantity! = this.available;
      this.calculatePayment();
    }
    if (this.orderItemCopy.quantity! < this.available) {
      this.orderItemCopy.quantity! = Number(
        (+this.orderItemCopy.quantity! + this.step).toFixed(3)
      );
      this.calculatePayment();
    }
  }

  public calculatePayment() {
    const result = this.calculatePaymentValue(this.orderItemCopy.quantity!);
    this.orderItemCopy.currentPrice = result.currentPrice;
    this.orderItemCopy.payment = result.payment;
  }

  public calculateQuantity() {
    this.orderItemCopy.currentPrice = this.getPriceToQuantity(
      this.orderItemCopy.payment!
    );
    const quantity: string = (
      this.orderItemCopy.payment! / this.orderItemCopy.currentPrice!
    ).toFixed(2);
    this.orderItemCopy.quantity = ["Infinity", "NaN"].includes(quantity)
      ? 0
      : this.unit === "u"
      ? Math.floor(Number(quantity))
      : Number(quantity);
  }

  public selectPrice(quantity: number) {
    if (this.rewardPrice) {
      return this.rewardPrice;
    } else {
      const prices =
        (!this.currentCustomer &&
          this.orderItemCopy.prices &&
          this.orderItemCopy.prices!.filter(
            p => p.member_level_name === "Normal"
          )) ||
        (this.batchCurrentPricing &&
          productService.getLocationPricingRule(
            { pricing: this.batchCurrentPricing.pricing },
            this.getCustomerCurrentMemberLevel
          ).price_breaks) ||
        [];
      let price: Pricebreak | undefined;
      let finalPrice: number;
      let finalPricePerUnit: number;
      if (
        this.orderItemCopy.price_point_by_usable_weight ||
        (this.batchCurrentPricing &&
          this.batchCurrentPricing!.pricing[0].pricing_style !== "units" &&
          this.productVariant!.requires_weighing === 0)
      ) {
        price = this.selectPriceForUsableWeight(prices);
        finalPricePerUnit = (price && price.post_tax! / price.quantity) || 0;
        finalPrice = finalPricePerUnit * this.usableWeight;
        finalPricePerUnit = finalPrice;
      } else {
        price =
          maxBy(prices.filter(p => p.quantity <= quantity), "quantity") ||
          minBy(prices, "quantity");
        finalPricePerUnit = (price && price.post_tax! / price.quantity) || 0;
        finalPrice = finalPricePerUnit * this.usableWeight;
      }
      EventBus.$emit("changePrice", finalPricePerUnit);
      return finalPrice;
    }
  }

  public getPriceToQuantity(payment: number) {
    const priceBreak = [...this.orderItemCopy.prices!].reverse().find(item => {
      const pricePerUnit = this.calculatePriceFromPriceBreak(item);
      const quantity = payment / pricePerUnit;
      const canBuyByWeigth = item.quantity / this.usableWeight <= quantity;
      return canBuyByWeigth;
    });

    if (priceBreak) {
      return this.calculatePriceFromPriceBreak(priceBreak);
    } else if (this.unit === "g") {
      return this.calculatePriceFromPriceBreak(this.orderItemCopy.prices![0]);
    } else {
      return this.rewardPrice;
    }
  }

  public addItemToOrder() {
    this.prescriptionDetail.pharmacist_id = this.pharmacistSelected;
    this.prescriptionDetail.doctor_id =
      this.currentCustomer &&
      this.currentCustomer!.profiles &&
      this.currentCustomer!.profiles[0]!.doctor_id
        ? String(this.currentCustomer.profiles[0].doctor_id)
        : null;
    this.orderItemCopy = {
      ...this.orderItemCopy,
      prescription_details: this.prescriptionDetail,
      pharmacist_id: this.pharmacistSelected
    };
    const item: AddToCartPayload = {
      orderItem: [this.orderItemCopy],
      loyalty_program_tier_id: this.loyaltyProgramTierId || null
    };
    this.$emit("addToCart", item);
  }

  public onlyNumber(event: KeyboardEvent) {
    if (isNaN(+event.key) && ![".", ","].includes(event.key)) {
      event.preventDefault();
    }
  }

  public quantityHandler() {
    if (this.orderItemCopy.unit && this.orderItemCopy.quantity) {
      this.orderItemCopy.quantity =
        this.unit === "u"
          ? Math.floor(Number(this.orderItemCopy.quantity))
          : ![".", ","].includes(
              this.orderItemCopy.quantity.toString().substr(-1)
            )
          ? this.orderItemCopy.quantity.toString().substr(-1) === "0"
            ? this.orderItemCopy.quantity
            : +(Math.trunc(+this.orderItemCopy.quantity * 1000) / 1000)
          : this.orderItemCopy.quantity;
      // Validates that the quantity is not greater than the available quantity
      this.orderItemCopy.quantity = Math.min(
        this.orderItemCopy.quantity,
        this.available
      );
      this.calculatePayment();
    }
  }

  public calculatePaymentValue(quantity: number) {
    let currentPrice!: number;
    let payment!: number;
    if (
      this.orderItemCopy.price_point_by_usable_weight ||
      (!this.productVariant!.requires_weighing &&
        this.batchCurrentPricing!.pricing[0].pricing_style === "weight")
    ) {
      let weightValue = this.productVariant!.usable_weight_value || 0;
      const weightUnit = this.productVariant!.usable_weight_unit;
      if (weightUnit === "mg") {
        weightValue = weightValue / 1000;
      } else if (weightUnit === "oz") {
        weightValue = weightValue * 28.35;
      }
      this.usableWeight = weightValue;
      currentPrice = this.selectPrice(quantity! * weightValue);
    } else {
      currentPrice = this.selectPrice(quantity! * this.usableWeight);
    }
    payment = currentPrice * quantity!;
    return {
      currentPrice,
      payment
    };
  }

  protected selectPriceForUsableWeight(prices: Pricebreak[]) {
    const usableQuantity =
      (this.orderItemCopy.quantity! || 1) * this.usableWeight;
    return (
      maxBy(prices.filter(p => p.quantity <= usableQuantity), "quantity") ||
      minBy(prices, "quantity")
    );
  }

  protected calculatePriceFromPriceBreak(priceBreak: Pricebreak) {
    return priceBreak.post_tax! / (priceBreak.quantity! / this.usableWeight);
  }

  get editDisable() {
    if (this.orderItem && this.orderItem.id) {
      return this.orderItemCopy.quantity === this.orderItem.quantity;
    }
    return false;
  }
}
