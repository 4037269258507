import Template from "@/components/retail/pos/pointOfSale/posCart/Discount/Discount.template.vue";
import ManualDiscountComponent from "@/components/retail/pos/pointOfSale/posCart/ManualDiscount/ManualDiscount.component";
import OverrideComponent from "@/components/retail/pos/pointOfSale/posCart/Override/Override.component";
import ProductsSelect from "@/components/retail/pos/pointOfSale/posCart/ProductsSelect/ProductsSelect.component";
import { overwriteDiscountCodes } from "@/enums/discountManager";
import { policyList } from "@/enums/permissions";
import { Discount } from "@/interfaces/discount";
import {
  AvailableDiscountGroup,
  ManualDiscounts,
  Order,
  OrderItem
} from "@/interfaces/order";
import { CallbackPromise } from "@/types/types";
import { BooleanCheck } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
const namespace: string = "OrderModule";

@Component({
  mixins: [Template],
  components: {
    OverrideComponent,
    ManualDiscountComponent
  }
})
export default class DiscountComponent extends Vue {
  @Prop({ required: true })
  public order!: Order;
  @Prop({ required: true })
  public orderItem!: OrderItem;
  @Action("addDiscount", { namespace })
  public addDiscountAction!: CallbackPromise<void>;
  @Getter("discounts", { namespace })
  public discounts!: Discount[];
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public loading = false;
  public canAddManual = false;
  public discountsGroups: AvailableDiscountGroup[] = [];
  public wholeOrderDiscountGroups: AvailableDiscountGroup[] = [];
  protected manualDiscounts: ManualDiscounts | null = null;

  public mounted() {
    this.loading = true;
    this.canAddManual = this.hasPermission(policyList.applyManualDiscounts);
    this.setManualDiscounts("LINE_ITEM");
    this.setGroups();
    this.loading = false;
    this.setWholeOrderDiscount();
  }
  public setWholeOrderDiscount() {
    this.discounts.forEach(data => {
      if (!data.is_system && data.scope === "WHOLE_ORDER") {
        this.wholeOrderDiscountGroups.push({
          items: [],
          discount: data
        });
      }
    });
  }

  public get orderHasItems() {
    return this.order.id && this.order.order_items!.length;
  }

  public get orderItemDiscounts(): AvailableDiscountGroup[] {
    return (
      (this.orderItem &&
        this.discountsGroups.filter(disc =>
          disc.items.includes(this.orderItem.id!)
        )) ||
      []
    );
  }

  public setGroups() {
    if (this.discounts.length) {
      const groups: AvailableDiscountGroup[] = [];
      if (typeof this.order.available_discounts === "object") {
        this.order.available_discounts = Object.values(
          this.order.available_discounts
        );
      }
      if (
        this.order.id &&
        this.orderHasItems &&
        this.order.available_discounts &&
        this.order.available_discounts.length
      ) {
        this.order.available_discounts.forEach(discount => {
          if (discount.discount_id && discount.scope === "LINE_ITEM") {
            const discountApplied: Discount | undefined = this.discounts.find(
              discountItem =>
                discountItem.id === discount.discount_id &&
                !discountItem.is_system
            );
            if (discountApplied) {
              groups.push({
                discount: discountApplied!,
                items: discount.items_ids!
              });
            }
          }
        });
      } else {
        // The order doesn't exists in the back end.
        this.discounts.forEach(d => {
          if (!d.is_system && d.scope === "LINE_ITEM") {
            groups.push({
              items: [],
              discount: d
            });
          }
        });
      }
      this.discountsGroups = groups;
    }
  }

  public addDiscount(discount: Discount) {
    const discountPayload: { [key: string]: any } = {
      discount_id: discount.id
    };
    if (this.orderItem) {
      discountPayload.item_id = this.orderItem.id!;
    }
    this.addDiscountAction({
      discount: [discountPayload],
      isEdit:
        this.order.applied_discounts &&
        !!this.order.applied_discounts.find(
          d =>
            d.discount_id === discount.id &&
            (!this.orderItem || this.orderItem.id === d.item_id)
        ),
      clear_other_line_item_discount: discount.clear_other_line_item_discount
    }).then(this.close);
  }

  public async openOverrideDiscount() {
    try {
      await this.$modals.load(
        OverrideComponent,
        {
          size: "min"
        },
        {
          orderItem: this.orderItem,
          manualDiscounts: this.manualDiscounts,
          orderDiscounts: this.order.applied_discounts || []
        }
      );
      this.close();
    } catch (e) {
      // Closing modal
    }
  }

  public async openManualDiscount(discountType: string) {
    try {
      await this.$modals
        .load(
          ManualDiscountComponent,
          {
            size: "fit",
            positionX: "center",
            positionY: "center"
          },
          {
            orderItem: cloneDeep(this.order.order_items),
            order: cloneDeep(this.order),
            manualDiscounts: this.manualDiscounts,
            orderDiscounts: this.order.applied_discounts || [],
            discountType
          }
        )
        .then(() => {
          this.close();
        });
    } catch (e) {
      // Closing modal
    }
  }

  public async openWholeOrderDiscount(
    discountType: string,
    discountName?: string,
    groups?: AvailableDiscountGroup[]
  ) {
    try {
      await this.$modals
        .load(
          ManualDiscountComponent,
          {
            size: "fit",
            positionX: "center",
            positionY: "center"
          },
          {
            orderItem: cloneDeep(this.order.order_items),
            order: cloneDeep(this.order),
            manualDiscounts: this.manualDiscounts,
            orderDiscounts: this.order.applied_discounts || [],
            discountType,
            discountName,
            groups
          }
        )
        .then(() => {
          this.close();
        });
    } catch (e) {
      // Closing modal
    }
  }

  public async openModalDiscountGroup(group: AvailableDiscountGroup) {
    try {
      const resp = await this.$modals.load(
        ProductsSelect,
        {
          size: "normal"
        },
        {
          group,
          orderItems: cloneDeep(this.order.order_items)
        }
      );
      await this.addDiscountAction({
        discount: resp,
        clear_other_line_item_discount:
          group.discount.clear_other_line_item_discount
      });
      this.close();
    } catch (e) {
      // Closing modal
    }
  }

  protected setManualDiscounts(type: string) {
    this.manualDiscounts = this.discounts
      .filter(dis => dis.scope === type)
      .reduce(
        (acc: ManualDiscounts, discount: Discount) => {
          if (
            discount.codename ===
            overwriteDiscountCodes.POS_PERCENTAGE_OVERWRITE
          ) {
            acc.order.percentage = discount.id;
          }

          if (
            discount.codename === overwriteDiscountCodes.POS_FIXED_OVERWRITE
          ) {
            acc.order.fixed = discount.id;
          }

          if (
            discount.codename ===
            overwriteDiscountCodes.POS_FIXED_OVERWRITE_LINE_ITEM
          ) {
            acc.item.fixed = discount.id;
          }

          if (
            discount.codename ===
            overwriteDiscountCodes.POS_PERCENTAGE_OVERWRITE_LINE_ITEM
          ) {
            acc.item.percentage = discount.id;
          }
          return acc;
        },
        {
          item: {
            fixed: 0,
            percentage: 0
          },
          order: {
            fixed: 0,
            percentage: 0
          }
        }
      );
  }

  protected close() {
    if (this.orderItem) {
      this.$emit("closeDiscountItem", this.orderItem);
    } else {
      this.$emit("closeDiscounts");
    }
  }
}
