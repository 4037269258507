import { render, staticRenderFns } from "./ExpandedPriceTooltip.template.vue?vue&type=template&id=9febc842&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9febc842",
  null
  
)

export default component.exports