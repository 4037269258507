import { render, staticRenderFns } from "./PosImage.template.vue?vue&type=template&id=69c447fe&scoped=true&"
var script = {}
import style0 from "./PosImage.template.vue?vue&type=style&index=0&id=69c447fe&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69c447fe",
  null
  
)

export default component.exports