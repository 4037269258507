import { Customer } from "@/interfaces/customer";
import { Discount } from "@/interfaces/discount";
import { Location } from "@/interfaces/location";
import { LoyalityItem, Order, OrderItem } from "@/interfaces/order";
import { User } from "@/interfaces/user";
import { orderService } from "@/services/order.service";
import { usersService } from "@/services/user.service";
import round from "lodash/round";
import { TranslateResult } from "vue-i18n";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./PrintPreOrderPendingReceipt.template.vue";

@Component({
  mixins: [Template],
  filters: {
    round: (value: number) => round(value, 2)
  }
})
export default class PrintPreOrderPendingReceiptComponent extends Vue {
  @Prop({ required: true })
  public order!: Order;
  public licenseNumber: number = 0;
  public title: TranslateResult | null = null;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public location!: Location;
  @Getter("user", { namespace: "AuthModule" })
  public storeUser!: User;
  public customer: Customer = this.order.customer!;

  public getUsableWeight(item: OrderItem) {
    return orderService.getUsableWeight(item);
  }

  public getDiscountLabel(discount: Discount, addCountable: number) {
    return orderService.getDiscountLabel(discount, addCountable);
  }
  public getLoyaltyDiscountLabel(loyalityItem: LoyalityItem) {
    return orderService.getLoyaltyDiscountLabel(loyalityItem);
  }

  public async mounted() {
    this.title = this.$t("pending_order_receipt");
    this.licenseNumber = await this.setLicenseNumber();
    this.$nextTick(() => this.$emit("readyToPrint"));
  }

  protected async setLicenseNumber() {
    if (
      this.storeUser &&
      this.order.user_id === this.storeUser.id &&
      this.storeUser.profile
    ) {
      return this.storeUser.profile.occupational_license_number;
    }
    const user = await usersService.getProfile(Number(this.order.user_id));
    this.storeUser = user;
    return user.profile!.occupational_license_number;
  }
}
