import { LocationSalesLimits } from "@/interfaces/location";
import { Order } from "@/interfaces/order";
import { RetailSettings } from "@/interfaces/retailSettings";
import { orderFulfillmentService } from "@/services/orderFulfillment.service";
import {
  FNS_DATE_FORMATS,
  fnsFormatDate,
  fnsParse,
  localDateToUTCTime,
  UTCTimeToLocalDate
} from "@/utils/date-fns.utils";
import {
  HelixDatePickerComponent,
  HelixDatePickerOptions
} from "helix-vue-components";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./CompleteCallIn.template.vue";
@Component({
  mixins: [Template],
  components: {
    HelixDatePickerComponent
  }
})
export default class CompleteCallInComponent extends Vue {
  @Getter("limitConfig", { namespace: "AuthModule" })
  public limitConfig!: LocationSalesLimits[];
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  @Prop({ required: true })
  public order!: Order;
  public openedPickers: { [key: string]: boolean } = {
    start_time: false,
    end_time: false
  };
  public loading: boolean = false;
  public saleHour = {
    enabled: false,
    from: "",
    to: ""
  };

  public date: string = "";
  public fromTime: Date | null = null;
  public toTime: Date | null = null;
  public hasFilled: boolean = false;
  public isPharmacyEnabled: boolean = false;
  public datePickerOption: Partial<HelixDatePickerOptions> = {
    required: true,
    name: "date",
    "value-format": FNS_DATE_FORMATS.BARS_DEFAULT,
    "picker-options": {
      disabledDate(date) {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        return date < today;
      }
    }
  };

  public get fromInputTime() {
    return this.fromTime
      ? fnsFormatDate(this.fromTime, FNS_DATE_FORMATS.LT)
      : "";
  }

  public get fromTimePicker() {
    return this.fromTime
      ? fnsFormatDate(this.fromTime, FNS_DATE_FORMATS.BASIC_TIME)
      : "";
  }

  /**
   * Set from time when timePicker change
   */
  public set fromTimePicker(value: string) {
    const parsedTime = fnsParse(value, "HH:mm");

    if (this.date) {
      this.setDateToTime(parsedTime);
    }

    this.fromTime = parsedTime;
  }

  /**
   * Format to time for display in input of the clock
   */
  public get toInputTime() {
    return this.toTime ? fnsFormatDate(this.toTime, FNS_DATE_FORMATS.LT) : "";
  }

  /**
   * Format to time for display in clock
   */
  public get toTimePicker() {
    return this.toTime
      ? fnsFormatDate(this.toTime, FNS_DATE_FORMATS.BASIC_TIME)
      : "";
  }

  /**
   * Update to time when timePicker change
   */
  public set toTimePicker(value: string) {
    const parsedTime = fnsParse(value, "HH:mm");

    if (this.date) {
      this.setDateToTime(parsedTime);
    }

    this.toTime = parsedTime;
  }

  /**
   * Update from a to time object with the date value
   */
  public updateTimes(): void {
    if (this.fromTime) {
      this.setDateToTime(this.fromTime);
    }

    if (this.toTime) {
      this.setDateToTime(this.toTime);
    }
  }

  public async save(): Promise<void> {
    if (!(await this.$validator.validateAll())) {
      return;
    }

    this.loading = true;
    const preOrder = await orderFulfillmentService.completeCallIn(
      this.order.pre_order!.uid,
      {
        pick_up_time_from: this.getPickUpTimeFormat(this.fromTime!),
        pick_up_time_to: this.getPickUpTimeFormat(this.toTime!),
        filled: this.hasFilled,
        update: false
      }
    );

    if (preOrder) {
      this.$emit("resolve", true);
    }
    this.loading = false;
  }

  protected created() {
    this.setSaleHour();

    this.$validator.extend("toTimeAllowedRule", {
      getMessage: () => this.$t("pos_call_in.invalid_time"),
      validate: this.toTimeAllowedRule
    });
    this.isPharmacyEnabled = !!this.retailSettings.pharmacy_mode_enabled;

    this.$validator.extend("fromTimeAllowedRule", {
      getMessage: () => this.$t("pos_call_in.invalid_time"),
      validate: this.fromTimeAllowedRule
    });

    this.$validator.extend("fromSaleHourLimit", {
      getMessage: () => this.$t("pos_call_in.invalid_time"),
      validate: () => this.saleHourRule(this.fromTime)
    });

    this.$validator.extend("toSaleHourLimit", {
      getMessage: () => this.$t("pos_call_in.invalid_time"),
      validate: () => this.saleHourRule(this.toTime)
    });
  }

  /**
   * Set sale Hour with limit config
   */
  private setSaleHour(): void {
    const saleHour = this.limitConfig.find(item => item.type === "sale_hour");

    if (!saleHour || !saleHour.enabled) {
      return;
    }

    const config = saleHour.configs.find(
      configItem => configItem.config.type === "hours"
    );

    if (!config) {
      return;
    }

    this.saleHour = {
      enabled: true,
      from: fnsFormatDate(
        UTCTimeToLocalDate(config.config.from),
        FNS_DATE_FORMATS.LT
      ),
      to: fnsFormatDate(
        UTCTimeToLocalDate(config.config.to),
        FNS_DATE_FORMATS.LT
      )
    };
  }

  private setDateToTime(time: Date) {
    if (this.date) {
      const date = fnsParse(this.date, FNS_DATE_FORMATS.BARS_DEFAULT);
      time.setDate(date.getDate());
      time.setMonth(date.getMonth());
      time.setFullYear(date.getFullYear());
    }
  }

  /**
   * Get today datetime with 0 seconds and miliseconds
   */
  private getToday(): Date {
    const today = new Date();
    today.setSeconds(0);
    today.setMilliseconds(0);
    return today;
  }

  private getPickUpTimeFormat(time: Date): string {
    const format = `${FNS_DATE_FORMATS.BARS_DEFAULT} ${
      FNS_DATE_FORMATS.LOCAL_TIME
    }`;
    const timeInUTC = localDateToUTCTime(time);
    const dateTime = fnsParse(`${this.date} ${timeInUTC}`, format);
    return fnsFormatDate(dateTime, FNS_DATE_FORMATS.DEFAULT_WITH_TIME);
  }

  /**
   * Custom validation rule for to time
   */
  private toTimeAllowedRule() {
    if (this.toTime === null) {
      return false;
    }

    if (this.toTime! >= this.getToday() && this.fromTime !== null) {
      return this.toTime! > this.fromTime;
    }

    return false;
  }

  /**
   * Custom validation rule for from time
   */
  private fromTimeAllowedRule() {
    if (this.fromTime === null) {
      return false;
    }

    return this.fromTime >= this.getToday();
  }

  /**
   * Custom validation rule to validate sale hour limit
   */
  private saleHourRule(time: Date | null) {
    if (!this.saleHour.enabled) {
      return true;
    }

    if (time === null) {
      return false;
    }

    const saleOpen = fnsParse(this.saleHour.from, FNS_DATE_FORMATS.LT);
    const saleClose = fnsParse(this.saleHour.to, FNS_DATE_FORMATS.LT);
    this.setDateToTime(saleOpen);
    this.setDateToTime(saleClose);
    return saleOpen <= time && time < saleClose;
  }
}
