import Template from "@/components/retail/pos/pointOfSale/posCart/Loyalty/Loyalty.template.vue";
import PointsComponent from "@/components/retail/pos/pointOfSale/posCart/Loyalty/Points/Points.component";
import ProgramsComponent from "@/components/retail/pos/pointOfSale/posCart/Loyalty/Programs/Programs.component";
import { AddToCartPayload, Order, OrderItem } from "@/interfaces/order";
import { EventBus } from "@/internal";
import { LoyaltyConfig } from "@/vuex/modules/order/order.types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import LinesComponent from "./Lines/Lines.component";

const namespace: string = "OrderModule";

@Component({
  mixins: [Template],
  components: {
    PointsComponent,
    ProgramsComponent,
    LinesComponent
  }
})
export default class LoyaltyComponent extends Vue {
  @Prop({ required: true })
  public order!: Order;
  @Prop({ required: true })
  public orderItem!: OrderItem;
  @Getter("loyaltyConfig", { namespace })
  public loyaltyConfig!: LoyaltyConfig;

  public notReady() {
    EventBus.$emit("notify", { text: "not_ready_yet", color: "warning" });
  }

  public closePoints(orderItem?: OrderItem) {
    if (orderItem) {
      this.$emit("closeItemPoints", orderItem);
    } else {
      this.$emit("closePoints");
    }
  }

  public addItem(item: AddToCartPayload) {
    this.$emit("addProgramItem", item);
    this.closePoints();
  }

  public get enforcePrograms() {
    return this.loyaltyConfig.loyalty_point.enforce_loyalty_points;
  }
}
