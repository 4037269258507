import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./ExpandedPriceTooltip.template.vue";

import { currencyFilter } from "@/filters/currency.filter";

import { Order, OrderItem } from "@/interfaces/order";
import { Callback } from "@/types/types";

export enum EXPANDED_PRICES_TYPES {
  ORDER = "ORDER",
  ITEM = "ITEM"
}

@Component({
  mixins: [Template],
  filters: { currencyFilter }
})
export default class ExpandedPriceTooltip extends Vue {
  @Prop() protected order!: Order;
  @Prop() protected item!: OrderItem;
  @Prop({ default: EXPANDED_PRICES_TYPES.ITEM })
  protected type!: EXPANDED_PRICES_TYPES;

  protected methodsDictionary: { [key: string]: Callback } = {
    "ORDER.price": () => {
      return this.getOrderPrice;
    },
    "ORDER.taxes": () => {
      return this.getOrderTaxes;
    },
    "ORDER.discounts": () => {
      return this.getOrderDiscounts;
    },
    "ORDER.total": () => {
      return this.getOrderTotal;
    },
    "ITEM.price": () => {
      return this.getItemPrice;
    },
    "ITEM.taxes": () => {
      return this.getItemTaxes;
    },
    "ITEM.discounts": () => {
      return this.getItemDiscounts;
    },
    "ITEM.total": () => {
      return this.getItemTotal;
    }
  };

  public get price() {
    return this.methodsDictionary[`${this.type}.price`]();
  }
  public get taxes() {
    return this.methodsDictionary[`${this.type}.taxes`]();
  }
  public get discounts() {
    return this.methodsDictionary[`${this.type}.discounts`]();
  }
  public get total() {
    return this.methodsDictionary[`${this.type}.total`]();
  }

  public get refound(): number {
    return (
      (this.order &&
        (this.order.credit &&
          this.order.credit!.length &&
          this.order.credit![0].amount)) ||
      0
    );
  }
  protected get getOrderPrice(): number {
    return this.order.sub_total || 0;
  }
  protected get getOrderTaxes(): number {
    return this.order.tax_total || 0;
  }
  protected get getOrderDiscounts(): number {
    return (
      (this.order.discounts &&
        this.order.discounts.reduce((agg, discount) => {
          return agg + discount.pre_tax_discount!;
        }, 0)) ||
      0
    );
  }
  protected get getOrderTotal(): number {
    return this.order.total! - this.refound;
  }

  protected get getItemPrice(): number {
    return this.item.base_price || 0;
  }
  protected get getItemTaxes(): number {
    return this.item.tax_amount || 0;
  }
  protected get getItemDiscounts(): number {
    return (
      (this.item.discounts &&
        this.item.discounts.reduce((agg, discount) => {
          return agg + discount.pre_tax_discount!;
        }, 0)) ||
      0
    );
  }
  protected get getItemTotal(): number {
    return this.item.price_final || 0;
  }
}
