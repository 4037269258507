import { Product } from "@/interfaces/product";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./PosImage.template.vue";

@Component({
  mixins: [Template]
})
export default class PosImageComponent extends Vue {
  @Prop({ required: true })
  protected imagePriority!: Array<{
    priority: number;
    type: string;
  }>;
  @Prop({ required: true })
  protected product!: Product;
  @Prop({ required: true })
  protected defaultImage!: string;
  protected image: string = this.defaultImage;

  public mounted() {
    this.product.images = {
      product:
        this.product.media &&
        this.product.media[0] &&
        this.product.media[0].media_thumb_url,
      brand: this.product.brand && this.product.brand.logo_thumb_url,
      strain:
        this.product.strain &&
        this.product.strain.avatar! &&
        this.product.strain.avatar!.avatar_thumb_url
    };

    this.imagePriority.find(priority => {
      if (this.product.images![priority.type]) {
        this.image = this.product.images![priority.type];
        return true;
      }
      return false;
    });
  }
}
