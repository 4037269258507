import { DiscountValues } from "@/enums/discountManager";
import { currencyFilter } from "@/filters/currency.filter";
import {
  AppliedDiscount,
  AvailableDiscountGroup,
  ManualDiscountFields,
  ManualDiscounts,
  Order,
  OrderItem,
  overrideDiscount,
  OverrideDiscount
} from "@/interfaces/order";
import { RetailSettings } from "@/interfaces/retailSettings";
import {
  PosManualDiscountFields,
  WholeProductSelectDiscount
} from "@/metadata/product";
import { truncate } from "@/utils/math.utils";

import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import { messagesService } from "@/services/messages.service";
import { CallbackPromise } from "@/types/types";
import {
  TableComponent,
  TableCounterInputComponent,
  TableHeader,
  TableItem,
  TableSelectEvent
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import sumBy from "lodash/sumBy";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./ManualDiscount.template.vue";
const namespace: string = "OrderModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent,
    TableCounterInputComponent
  },
  filters: {
    currencyFilter
  }
})
export default class ManualDiscountComponent extends Vue {
  @Prop({ required: true }) public orderItem!: OrderItem[];
  @Prop({ required: true }) public manualDiscounts!: ManualDiscounts;
  @Prop({ required: true }) public orderDiscounts!: AppliedDiscount[];
  @Prop({ required: true }) public discountType!: string;
  @Prop({ required: true }) public order!: Order;
  @Prop({ default: null }) public discountName!: string | null;
  @Prop() public groups!: AvailableDiscountGroup;
  public model: OverrideDiscount = cloneDeep(overrideDiscount);
  public applyTypes: Array<{ name: string; value: DiscountValues }> = [];
  @Action("addDiscount", { namespace })
  public addDiscountAction!: CallbackPromise<void>;
  public applyType: "FIXED" | "PERCENTAGE" = "FIXED";
  public isEdit = false;
  public isValid = false;
  public pinService!: SecurityPinService;
  public headers: TableHeader[] = [];
  public currentStep = 1;
  public manualDiscountFields: ManualDiscountFields[] = [];
  public wholeOrderDiscountFields: ManualDiscountFields[] = [];
  public defaultSelectedItems: number[] = [];
  public isLoadingDiscount = false;
  public isLoading = false;
  public units = 0;
  public weight = 0;
  public orderItemsSelected: OrderItem[] = [];
  public totalDiscount = 0;
  public batches = 0;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  protected retailSettings!: RetailSettings;

  public created() {
    if (this.discountName) {
      this.isLoading = true;
      this.currentStep++;
      this.wholeOrderItems();
    }
  }

  public wholeOrderItems() {
    if (this.discountName) {
      setTimeout(() => {
        this.orderItem.forEach(items => {
          this.defaultSelectedItems.push(Number(items.id));
        });
      }, 100);

      this.setTableFields();
    }
  }

  public onSelectItem(data: TableItem) {
    if (data.eventItem) {
      const index = this.manualDiscountFieldsIndex(data.eventItem.item);
      if (index !== -1) {
        this.manualDiscountFields[index].is_selected = data.eventItem.status;
      }
    } else {
      data.currentSelection.map((items: ManualDiscountFields) => {
        const index = this.manualDiscountFieldsIndex(items);
        if (index !== -1) {
          this.manualDiscountFields[index].is_selected = data.checkAll;
        }
      });
    }
    this.totalBatches();
    this.totalWeight();
    this.totalUnits();
    this.totalTotalDiscount();
  }

  public validateQuantity(event: KeyboardEvent, value: string | number | null) {
    if (value && !/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(value.toString())) {
      event.preventDefault();
    }
  }
  public get manualDiscountFieldsCheck() {
    return this.manualDiscountFields.filter(item => item.is_selected).length;
  }
  public async addDiscount() {
    if (await this.$validator.validate()) {
      try {
        const orderItems = cloneDeep(this.orderItem);
        const pin = await this.pinService.ensure(
          this.$t("security_pin.title").toString(),
          this.$t("cart_discounts.pin_text").toString()
        );
        const discountFields = [];
        if (this.manualDiscountFields) {
          if (this.discountType === "multiple_item") {
            for (const items of this.manualDiscountFields) {
              if (items.is_selected) {
                discountFields.push({
                  amount:
                    this.applyType === "FIXED"
                      ? Number(items.total_discount)
                      : items.discount,
                  description: this.model.description,
                  item_quantity: Number(items.quantity),
                  discount_id:
                    this.applyType === "FIXED"
                      ? this.manualDiscounts.item.fixed
                      : this.manualDiscounts.item.percentage,
                  item_id: Number(items.id),
                  post_tax: this.model.post_tax
                });
                const orderItemIndex = this.orderItem.findIndex(
                  data => data.id === items.id
                );
                if (orderItemIndex !== -1) {
                  if (
                    Number(items.quantity) <
                    Number(this.orderItem[orderItemIndex].quantity)
                  ) {
                    orderItems.push({
                      ...orderItems[orderItemIndex],
                      id: null,
                      order_item_uid: null,
                      quantity:
                        Number(orderItems[orderItemIndex].quantity) -
                        Number(items.quantity)
                    });
                  }
                  orderItems[orderItemIndex].quantity = items.quantity;
                }
              }
            }
          } else if (this.discountType === "whole_order") {
            discountFields.push({
              amount: this.model.amount,
              description: this.model.description,
              discount_id:
                this.applyType === "FIXED"
                  ? this.manualDiscounts.order.fixed
                  : this.manualDiscounts.order.percentage,
              items_ids: this.manualDiscountFields
                .filter(mdf => mdf.is_selected)
                .map(mdf => mdf.id),
              post_tax: this.model.post_tax
            });
          }
        }

        this.addDiscountAction({
          discount: discountFields,
          pin,
          isEdit: this.isEdit,
          orderItems
        }).then(() => this.$emit("resolve", null));
      } catch (e) {
        messagesService.renderWarningMessage("required_pin");
      }
    }
  }

  public async addWholeOrderDiscount() {
    const orderItems = cloneDeep(this.orderItem);
    const discountFields = [];
    discountFields.push({
      amount: 1,
      description: this.groups.discount.discount_description_to_be_print,
      discount_id: this.groups.discount.id,
      items_ids: this.manualDiscountFields
        .filter(mdf => mdf.is_selected)
        .map(mdf => mdf.id),
      post_tax: this.groups.discount.post_tax
    });

    this.addDiscountAction({
      discount: discountFields,
      isEdit: this.isEdit,
      orderItems,
      clear_other_line_item_discount: this.groups.discount
        .clear_other_line_item_discount
    }).then(() => this.$emit("resolve", null));
  }

  public mounted() {
    this.headers =
      this.discountType === "multiple_item"
        ? PosManualDiscountFields
        : WholeProductSelectDiscount;
    this.applyTypes = [
      {
        name: "%",
        value: DiscountValues.PERCENTAGE
      },
      {
        name: "$",
        value: DiscountValues.FIXED
      }
    ];
    this.checkForExistent();
    this.pinService = new SecurityPinService();
  }
  public setOrderItems(event: TableSelectEvent) {
    this.orderItemsSelected = cloneDeep(event.currentSelection);
  }

  public async showNextStep() {
    if (await this.$validator.validate()) {
      this.isLoading = true;
      this.currentStep++;
      this.setTableFields();
    }
  }

  public setTableFields() {
    this.manualDiscountFields = [];
    let itemPrice!: number;
    let wholeOrderItemPrice!: number;
    this.orderItem.forEach(items => {
      let totalDiscount = 0;
      let isSelected = true;
      if (this.discountName) {
        wholeOrderItemPrice = this.groups.discount.post_tax
          ? Number(items.base_price) + Number(items.tax_amount)
          : Number(items.base_price);

        if (this.groups.discount.type === "FIXED_DISCOUNT_TYPE") {
          if (
            items.product_info &&
            items.product_info!.product_unit &&
            items.product_info.product_unit === "g"
          ) {
            totalDiscount = Number(this.groups.discount.type_attributes.rate);
          } else {
            totalDiscount =
              Number(items.quantity) *
              Number(this.groups.discount.type_attributes.rate);
          }
        } else if (this.groups.discount.type === "PERCENTAGE_DISCOUNT_TYPE") {
          if (this.discountType === "whole_order") {
            const value = (
              (Number(items.base_price) *
                Number(this.groups.discount.type_attributes.rate)) /
              100
            ).toFixed(2);
            totalDiscount = Number(value);
          } else {
            const value = (
              (wholeOrderItemPrice *
                Number(this.groups.discount.type_attributes.rate)) /
              100
            ).toFixed(2);
            totalDiscount = Number(value);
          }
        }
        // this.defaultSelectedItems.push(Number(items.id));
      } else {
        itemPrice = this.model.post_tax
          ? Number(items.base_price) + Number(items.tax_amount)
          : Number(items.base_price);
        if (this.applyType === "FIXED") {
          if (
            items.product_info &&
            items.product_info!.product_unit &&
            items.product_info.product_unit === "g"
          ) {
            totalDiscount = Number(this.model.amount);
          } else {
            totalDiscount = Number(items.quantity) * Number(this.model.amount);
          }
        } else {
          if (this.discountType === "whole_order") {
            const value = (
              (Number(items.base_price) * Number(this.model.amount)) /
              100
            ).toFixed(2);
            totalDiscount = Number(value);
          } else {
            const value = (
              (itemPrice * Number(this.model.amount)) /
              100
            ).toFixed(2);
            totalDiscount = Number(value);
          }
        }
        if (
          this.discountType === "whole_order" &&
          items!.discounts!.length !== 0
        ) {
          isSelected = false;
        }
      }

      this.manualDiscountFields.push({
        batch: items.batch_barcode_uid,
        product: items.product_info!.name,
        quantity: items.quantity,
        discount:
          this.model.amount || this.groups.discount.type_attributes.rate,
        total_discount: totalDiscount,
        product_unit: items.product_info!.product_unit,
        discounts: items.discounts,
        product_info: items.product_info,
        id: items.id,
        item_price: itemPrice,
        is_selected: isSelected
      });
      if (!this.discountName && isSelected) {
        this.defaultSelectedItems.push(Number(items.id));
      }
    });
    this.totalBatches();
    this.totalWeight();
    this.totalUnits();
    this.totalTotalDiscount();
    this.isLoading = false;
  }

  public checkExclusiveDiscount(data: ManualDiscountFields) {
    return data.discounts!.filter(disc => disc.exclusive_discount === 1).length
      ? true
      : false;
  }

  public disableQuanityIncrease(data: ManualDiscountFields) {
    const index = this.manualDiscountFieldsIndex(data);
    const selectedOrderItem = this.getOrderItem(data);

    if (index !== -1 && selectedOrderItem) {
      if (
        Number(this.manualDiscountFields[index].quantity) ===
        Number(selectedOrderItem.quantity)
      ) {
        return true;
      }
    }
    return false;
  }

  public manualDiscountFieldsIndex(data: ManualDiscountFields) {
    const index = this.manualDiscountFields.findIndex(
      item => item.id === data.id
    );
    return index;
  }

  public decreaseQuantity(data: ManualDiscountFields) {
    const index = this.manualDiscountFieldsIndex(data);

    const selectedOrderItem = this.getOrderItem(data);
    if (index !== -1 && selectedOrderItem) {
      const quantity = Number(data.quantity) - 1;
      this.manualDiscountFields[index].quantity = quantity;
      if (this.applyType === "FIXED") {
        this.manualDiscountFields[index].total_discount =
          quantity * Number(data.discount);
      } else {
        const totalTax = this.model.post_tax
          ? Number(selectedOrderItem.tax_amount!.toFixed(2)) /
            Number(selectedOrderItem.quantity)
          : 0;
        const totalPrice =
          Number(totalTax) + Number(selectedOrderItem.unit_base_price);
        const itemPrice = totalPrice * quantity;

        const value = ((itemPrice * Number(data.discount)) / 100).toFixed(2);
        this.manualDiscountFields[index].total_discount = Number(value);
      }
      this.totalBatches();
      this.totalWeight();
      this.totalUnits();
      this.totalTotalDiscount();
    }
  }
  public increaseQuantity(data: ManualDiscountFields) {
    const index = this.manualDiscountFieldsIndex(data);

    const selectedOrderItem = this.getOrderItem(data);
    if (index !== -1 && selectedOrderItem) {
      const quantity = Number(data.quantity) + 1;
      this.manualDiscountFields[index].quantity = quantity;
      if (this.applyType === "FIXED") {
        this.manualDiscountFields[index].total_discount =
          quantity * Number(data.discount);
      } else {
        const totalTax = this.model.post_tax
          ? Number(selectedOrderItem.tax_amount!.toFixed(2)) /
            Number(selectedOrderItem.quantity)
          : 0;
        const totalPrice =
          Number(totalTax) + Number(selectedOrderItem.unit_base_price);
        const itemPrice = totalPrice * quantity;

        const value = ((itemPrice * Number(data.discount)) / 100).toFixed(2);
        this.manualDiscountFields[index].total_discount = Number(value);
      }
      this.totalBatches();
      this.totalWeight();
      this.totalUnits();
      this.totalTotalDiscount();
    }
  }
  public decreaseDiscount(data: ManualDiscountFields) {
    const index = this.manualDiscountFieldsIndex(data);

    const selectedOrderItem = this.getOrderItem(data);
    if (index !== -1 && selectedOrderItem) {
      const discount = Number(data.discount) - 1;
      this.manualDiscountFields[index].discount = discount;
      if (this.applyType === "FIXED") {
        if (data.product_unit === "g") {
          this.manualDiscountFields[index].total_discount = discount;
        } else {
          this.manualDiscountFields[index].total_discount =
            Number(data.quantity) * discount;
        }
      } else {
        let itemPrice = Number(data.item_price);
        if (data.quantity !== selectedOrderItem.quantity) {
          const totalTax = this.model.post_tax
            ? Number(selectedOrderItem.tax_amount!.toFixed(2)) /
              Number(selectedOrderItem.quantity)
            : 0;
          const totalPrice =
            Number(totalTax) + Number(selectedOrderItem.unit_base_price);
          itemPrice = totalPrice * Number(data.quantity);
        }

        const value = ((itemPrice * Number(data.discount)) / 100).toFixed(2);
        this.manualDiscountFields[index].total_discount = Number(value);
      }
      this.totalBatches();
      this.totalWeight();
      this.totalUnits();
      this.totalTotalDiscount();
    }
  }
  public increaseDiscount(data: ManualDiscountFields) {
    const index = this.manualDiscountFieldsIndex(data);

    const selectedOrderItem = this.getOrderItem(data);
    if (index !== -1 && selectedOrderItem) {
      const discount = Number(data.discount) + 1;
      this.manualDiscountFields[index].discount = discount;
      if (this.applyType === "FIXED") {
        if (data.product_unit === "g") {
          this.manualDiscountFields[index].total_discount = discount;
        } else {
          this.manualDiscountFields[index].total_discount =
            Number(data.quantity) * discount;
        }
      } else {
        let itemPrice = Number(data.item_price);
        if (data.quantity !== selectedOrderItem.quantity) {
          const totalTax = this.model.post_tax
            ? Number(selectedOrderItem.tax_amount!.toFixed(2)) /
              Number(selectedOrderItem.quantity)
            : 0;
          const totalPrice =
            Number(totalTax) + Number(selectedOrderItem.unit_base_price);
          itemPrice = totalPrice * Number(data.quantity);
        }

        const value = ((itemPrice * Number(data.discount)) / 100).toFixed(2);
        this.manualDiscountFields[index].total_discount = Number(value);
      }
      this.totalBatches();
      this.totalWeight();
      this.totalUnits();
      this.totalTotalDiscount();
    }
  }

  public onChangeItemValue(data: ManualDiscountFields, field: string) {
    const index = this.manualDiscountFieldsIndex(data);

    const selectedOrderItem = this.getOrderItem(data);
    if (index !== -1 && selectedOrderItem) {
      if (
        field === "quantity" &&
        Number(data.quantity) >= Number(selectedOrderItem.quantity)
      ) {
        data.quantity = selectedOrderItem.quantity;
        this.manualDiscountFields[index].quantity = selectedOrderItem.quantity;
      }
      if (this.applyType === "FIXED") {
        if (data.product_unit === "g") {
          this.manualDiscountFields[index].total_discount = Number(
            data.discount
          );
        } else {
          this.manualDiscountFields[index].total_discount =
            Number(data.quantity) * Number(data.discount);
        }
      } else {
        let itemPrice = Number(data.item_price);
        if (data.quantity !== selectedOrderItem.quantity) {
          const totalTax = this.model.post_tax
            ? Number(selectedOrderItem.tax_amount!.toFixed(2)) /
              Number(selectedOrderItem.quantity)
            : 0;
          const totalPrice =
            Number(totalTax) + Number(selectedOrderItem.unit_base_price);
          itemPrice = totalPrice * Number(data.quantity);
        }

        const value = ((itemPrice * Number(data.discount)) / 100).toFixed(2);
        this.manualDiscountFields[index].total_discount = Number(value);
      }
    }
  }

  public getOrderItem(data: ManualDiscountFields) {
    const index = this.orderItem.findIndex(item => item.id === data.id);

    if (index !== -1) {
      return this.orderItem[index];
    }
  }

  public appliedDiscountItem(id: number) {
    return (
      (this.order.applied_discounts &&
        this.order.applied_discounts.filter(item => item.item_id === id)) ||
      []
    );
  }

  public priceFinalAlert(data: ManualDiscountFields) {
    const selectedOrderItem = this.getOrderItem(data);

    const discountsItem = this.appliedDiscountItem(selectedOrderItem!.id!);
    if (discountsItem.length) {
      return (
        selectedOrderItem!.product_info!
          .block_item_from_being_sold_at_zero_cost! &&
        selectedOrderItem!.price_raw! -
          (sumBy(discountsItem, item => +item.applicable_amount) +
            Number(data.total_discount)) <=
          0
      );
    } else {
      return (
        selectedOrderItem!.product_info!
          .block_item_from_being_sold_at_zero_cost! &&
        selectedOrderItem!.price_raw! - Number(data.total_discount) <= 0
      );
    }
  }

  public priceUnitAlert(data: ManualDiscountFields) {
    const selectedOrderItem = this.getOrderItem(data);

    const discountsItem = this.appliedDiscountItem(selectedOrderItem!.id!);
    if (discountsItem.length) {
      return (
        selectedOrderItem!.product_info!
          .block_item_from_being_sold_below_cost_per_unit! &&
        selectedOrderItem!.price_raw! -
          (sumBy(discountsItem, item => +item.applicable_amount) +
            Number(data.total_discount)) <
          +selectedOrderItem!.product_info!.cost_per_unit!
      );
    } else {
      return (
        selectedOrderItem!.product_info!
          .block_item_from_being_sold_below_cost_per_unit! &&
        selectedOrderItem!.price_raw! - Number(data.total_discount) <
          +selectedOrderItem!.product_info!.cost_per_unit!
      );
    }
  }

  public getFinalPriceValue(data: ManualDiscountFields) {
    const selectedOrderItem = this.getOrderItem(data);

    return (
      Number(selectedOrderItem!.price_final) -
      Number(selectedOrderItem!.tax_amount)
    );
  }

  public checkForExistent() {
    this.isEdit = false;
    this.model = cloneDeep(overrideDiscount);
    this.model.post_tax = !this.retailSettings!.retail_pre_tax_pricing;
  }

  public cancel() {
    this.$emit("reject");
  }

  public totalBatches() {
    this.batches = 0;
    if (this.manualDiscountFields) {
      this.manualDiscountFields.forEach(data => {
        if (data.is_selected) {
          this.batches++;
        }
      });
    }
  }

  public totalWeight() {
    this.weight = 0;
    if (this.manualDiscountFields) {
      this.manualDiscountFields.forEach(data => {
        if (data.product_unit === "g") {
          if (data.is_selected) {
            this.weight += Number(data.quantity)!;
          }
        }
      });
    }
  }

  public totalUnits() {
    this.units = 0;
    if (this.manualDiscountFields) {
      this.manualDiscountFields.forEach(data => {
        if (data.product_unit === "u") {
          if (data.is_selected) {
            this.units += Number(data.quantity)!;
          }
        }
      });
    }
  }
  public totalTotalDiscount() {
    this.totalDiscount = 0;
    if (this.discountType === "whole_order") {
      if (this.discountName) {
        if (this.groups.discount.type === "FIXED_DISCOUNT_TYPE") {
          this.totalDiscount = Number(
            this.groups.discount.type_attributes.rate
          );
        } else if (this.groups.discount.type === "PERCENTAGE_DISCOUNT_TYPE") {
          const totalBasePrice = this.order.order_items!.reduce(
            (total, item) => total + item.base_price!,
            0
          );
          this.totalDiscount = truncate(
            (Number(totalBasePrice) *
              Number(this.groups.discount.type_attributes.rate)) /
              100,
            2
          );
        }
      } else {
        if (this.applyType === "FIXED") {
          this.totalDiscount = Number(this.model.amount);
        } else {
          this.totalDiscount =
            (Number(this.order.sub_total) * Number(this.model.amount)) / 100;
        }
      }
    } else {
      if (this.manualDiscountFields) {
        this.manualDiscountFields.forEach(data => {
          if (data.is_selected) {
            this.totalDiscount += Number(data.total_discount)!;
          }
        });
      }
    }
  }
}
