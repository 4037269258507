import { Discount } from "@/interfaces/discount";
import { OrderItem } from "@/interfaces/order";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./ExpandedPrices.template.vue";

@Component({
  mixins: [Template],
  filters: {
    expandedPrice
  }
})
export default class ExpandedPricesComponent extends Vue {
  @Prop() public item!: OrderItem;

  public getDiscountLabel(discount: Discount) {
    if (discount.name) {
      if (discount.description) {
        return discount.description;
      }
      return discount.name;
    }
  }
}

function expandedPrice(price: string | number) {
  return `$${price}`;
}
