import Template from "@/components/retail/pos/pointOfSale/posCart/Loyalty/Programs/Programs.template.vue";
import PosProductModalComponent from "@/components/retail/pos/pointOfSale/posProductList/posProductModal/PosProductModal.component";
import { Batch, ProductBatch } from "@/interfaces/batch";
import {
  LoyaltyProgram,
  LoyaltyProgramTier
} from "@/interfaces/loyaltyProgram";
import { messagesService } from "@/services/messages.service";
import { productService } from "@/services/product.service";
import { LoyaltyConfig } from "@/vuex/modules/order/order.types";
import Cookie from "js-cookie";
import sortBy from "lodash/sortBy";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

const namespace: string = "OrderModule";

@Component({
  mixins: [Template],
  components: {
    PosProductModalComponent
  }
})
export default class ProgramsComponent extends Vue {
  @Getter("loyaltyConfig", { namespace })
  public loyaltyConfig!: LoyaltyConfig;
  public loyaltyPrograms!: LoyaltyProgram[];
  public loyaltyTotals!: {};
  public isLoading: boolean = false;
  public amountStrings: {} = {};
  public redemptionStep = 1;
  public programsWithRewards: LoyaltyProgram[] = [];

  public async modalToggle(i: number, tier: LoyaltyProgramTier) {
    const assignedPharmacistUserPayload = Cookie.get("pos_pharmacist") || null;
    if (!this.isLoading) {
      try {
        this.isLoading = true;
        const product = await productService.findBySkuId(tier.product!.sku!, {
          embed: "batches"
        });
        if (
          (product.batches as ProductBatch[]).filter(b => !!b.on_sale.length)
            .length
        ) {
          this.$modals
            .load(
              PosProductModalComponent,
              {
                size: "normal"
              },
              {
                product: await productService.setExpandedProductView(product),
                pharmacistSelected:
                  assignedPharmacistUserPayload &&
                  assignedPharmacistUserPayload !== "undefined"
                    ? Number(assignedPharmacistUserPayload)
                    : 0,
                programs: {
                  position: i! + 1,
                  rewardMax: tier.max_quantity_you_can_carry,
                  rewardPrice: tier.price,
                  loyaltyProgramTierId: tier.id
                }
              }
            )
            .then(
              (item: any) => {
                if (item) {
                  this.$emit("addItem", item);
                }
              },
              () => {
                // Nothing to do on close
              }
            );
        } else {
          messagesService.renderWarningMessage("current_inventory.no_batches");
        }
      } catch (e) {
        messagesService.renderErrorMessage(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  public mounted() {
    this.setPrograms();
    this.amountStrings = {
      NUMBER_OF_VISITS: this.$t("customer.visits"),
      LOYALTY_POINTS: this.$t("customer.points")
    };
  }

  public getCurrentRewards(program: LoyaltyProgram) {
    if (!program.program_tiers) {
      return;
    }
    let programTiers: LoyaltyProgramTier[];
    if (!Array.isArray(program.program_tiers)) {
      const allKeysNumbers = Object.keys(program.program_tiers).every(
        key => !Number.isNaN(parseInt(key, 10))
      );
      if (allKeysNumbers) {
        programTiers = Object.values(program.program_tiers);
      } else {
        return;
      }
    } else {
      programTiers = program.program_tiers;
    }

    return sortBy(
      programTiers.filter((tier: LoyaltyProgramTier) => {
        return tier.apply;
      }),
      ["based_on_value"]
    );
  }

  public notReady() {
    messagesService.renderWarningMessage("not_ready_yet");
  }

  @Watch("loyaltyConfig")
  public setPrograms() {
    if (this.loyaltyConfig) {
      this.loyaltyPrograms = this.loyaltyConfig.programs;
      this.loyaltyTotals = this.loyaltyConfig.total_points;
      this.programsWithRewards =
        this.loyaltyPrograms &&
        this.loyaltyPrograms.filter((program: LoyaltyProgram) => {
          const rewards = this.getCurrentRewards(program);
          return rewards && rewards.length;
        });
    }
  }
}
